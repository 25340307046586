import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const getAllTimesheets = generateService(async (params) => {
  const { data } = await Api.get(`timesheets/`, {
    params,
  })

  return data
})

export const getTimesheetDetails = generateService(async (id) => {
  const { data } = await Api.get(`timesheets/${id}`)

  return data
})

export const bulkUploadTimesheets = async (body) => {
  const { data } = await Api.post(`timesheets/bulk-create/`, body)

  return data
}

export const setTimesheetReviewAction = async (params) => {
  const { data } = await Api.post(`timesheets/${params.id}/action/`, params.body)

  return data
}
