import React from 'react'
import styled from 'styled-components'

const Container = styled.div``
const Title = styled.h1`
  font-weight: 800;
  font-size: 28px;
  line-height: 36px;
  color: #121212;
  margin-bottom: 0.5rem;

  span {
    color: #40b84c;
  }
`
const Text = styled.div`
  color: #878787;
  font-size: 14px;
  line-height: 150%;
`

export const WelcomeTitle = ({ firstName, description }) => {
  return (
    <Container>
      <Title>
        Welcome, <span>{firstName}</span> 👋
      </Title>
      <Text>{description}</Text>
    </Container>
  )
}
