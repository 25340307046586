import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { BsLink } from 'react-icons/bs'
import { MdOutlineInsertDriveFile } from 'react-icons/md'
import styled from 'styled-components'

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #121212;
  margin-bottom: 8px;
`

const Template = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 480px;
  font-weight: 500;
  font-size: 14px;
  color: #121212;
  margin-bottom: 8px;
`
const Name = styled.div`
  flex: 1;
`
const FileIcon = styled(MdOutlineInsertDriveFile)`
  color: #40b84c;
  margin-right: 8px;
`

export default function FileInfo({ label, name, file }) {
  const handlePreview = () => {
    if (!file) return
    window.open(file, '_blank')
  }
  return (
    <div>
      <Label>{label}</Label>
      <Template>
        <Name>
          <FileIcon /> {name}
        </Name>
        {file && (
          <Button
            data-testid="file-info.component-52610F"
            priority="secondary"
            style={{ height: '32px' }}
            size="small"
            type="button"
            className="px-2 pl-0"
            onClick={handlePreview}
          >
            <BsLink />
            <Typography className="text_light__12 ml-2">Preview</Typography>
          </Button>
        )}
      </Template>
    </div>
  )
}
