/* eslint-disable */
import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import ModalSide from '@atoms/ModalSide/ModalSide'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Toggle from '@atoms/Toggle'
import Typography from '@atoms/Typography/Typography'
import UploadButton from '@atoms/UploadButton'
import { InfoMessage } from '@components/info-message/info-message.component'
import { TIME_OFF_LEAVE_TYPES, TIME_OFF_STATUSES } from '@core/constants'
import { AppContext } from '@core/context'
import { employeeTableParse } from '@pages/employees/parse'
import { calculateDayOff } from '@services/absences.service'
import { fetchContractors, fetchUploadFile } from '@services/app.service'
import { getContractsPaidLeaves } from '@services/contract.service'
import { DotWave } from '@uiball/loaders'
import { parseISO } from 'date-fns'
import moment from 'moment'
import React, { useContext, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { v4 } from 'uuid'
import AttachmentFormItem from '../time-off-attachment-form-item'

import { Styled } from './time-off-modal.styles'

const formId = v4()

export const TimeOffModal = ({
  onClose,
  onSubmit,
  onCancel,
  onReject,
  onApprove,
  loading,
  isLoadingApprove,
  isLoadingReject,
  isLoadingCancel,
  isEdit,
  isReview,
  isDisabled,
  title,
  timeOff,
  onAttachmentChange,
  onAttachmentDelete,
  isAttachmentLoading,
}) => {
  const { profile } = useContext(AppContext)
  const [doc, setDoc] = useState({
    file_uuid: timeOff?.filename || undefined,
  })
  const [totalDayOff, setTotalDayOff] = useState()
  const [contracts, setContracts] = useState([])
  const [rejectReason, setRejectReason] = useState('')

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      contract: timeOff?.contract.id || undefined,
      start_date: timeOff?.start_date ? parseISO(timeOff.start_date) : undefined,
      end_date: timeOff?.end_date ? parseISO(timeOff.end_date) : undefined,
      is_one_day_absence: timeOff?.is_one_day_absence || false,
      current_country: timeOff?.current_country.id || undefined,
      leave_reason_type: timeOff?.leave_reason_type || undefined,
      start_date_is_half_day: timeOff?.start_date_is_half_day || false,
      end_date_is_half_day: timeOff?.end_date_is_half_day || false,
      comment: timeOff?.comment || undefined,
    },
  })

  const starts_at_watch = watch('start_date')
  const end_at_watch = watch('end_date')
  const oneDayWatch = watch('is_one_day_absence')
  const end_half_watch = watch('end_date_is_half_day')
  const start_half_watch = watch('start_date_is_half_day')
  const currentCountry = watch('current_country')
  const contract = watch('contract')

  const { isLoading, isFetching } = useQuery([fetchContractors.key], {
    queryFn: () =>
      fetchContractors.fetch({
        company_id: profile.id,
        state: 'active',
        offset: 0,
        limit: 1000,
      }),
    onSuccess: ({ results }) => {
      setContracts(employeeTableParse(results))
    },
  })

  const { data, isLoading: isLoadingPaid } = useQuery(['getContractsPaidLeaves', contract], {
    queryFn: () => getContractsPaidLeaves(contract.value || contract),
    enabled: !!contract,
  })

  const { isLoading: isLoadingTotalDayOff } = useQuery(
    [
      'calculateDayOff',
      starts_at_watch,
      end_at_watch,
      currentCountry,
      oneDayWatch,
      contract,
      end_half_watch,
      start_half_watch,
    ],
    {
      queryFn: () => {
        const params = {
          start_date: moment(starts_at_watch).format('YYYY-MM-DD'),
          end_date: !oneDayWatch && end_at_watch ? moment(end_at_watch).format('YYYY-MM-DD') : undefined,
          start_date_is_half_day: start_half_watch || undefined,
          end_date_is_half_day: end_half_watch || undefined,
          country: currentCountry || contract.countryId,
        }
        const regionId = timeOff?.contract?.region?.id || contract.regionId
        if (regionId) params.region = regionId
        return calculateDayOff(params)
      },
      onSuccess: ({ data }) => {
        setTotalDayOff(Object.values(data)[0])
      },
      enabled: (!!currentCountry || !!contract) && !!starts_at_watch,
    }
  )

  const checkAllRequire = useMemo(() => {
    const isRequiredDates = oneDayWatch ? starts_at_watch : starts_at_watch && end_at_watch
    return !!(starts_at_watch && contract && isRequiredDates)
  }, [starts_at_watch, oneDayWatch, end_at_watch, contract])

  const handleCreateTimeOff = (formValues) => {
    const data = {
      ...formValues,
      start_date: moment(formValues.start_date).format('YYYY-MM-DD'),
      // eslint-disable-next-line no-nested-ternary
      end_date: oneDayWatch
        ? moment(formValues.start_date).format('YYYY-MM-DD')
        : formValues.end_date
          ? moment(formValues.end_date).format('YYYY-MM-DD')
          : undefined,
      contract: formValues.contract.value || formValues.contract,
      current_country: formValues.current_country || formValues.contract.countryId,
      // eslint-disable-next-line no-nested-ternary
      leave_reason_type: formValues.leave_reason_type.value || formValues.leave_reason_type,
      request_creator_role: 'COMPANY',
    }

    if (!timeOff) {
      data.attached_document = doc.file_uuid === timeOff?.filename ? undefined : doc.file_uuid
    }

    onSubmit(data)
  }

  const handleUpload = async (file) => {
    const setProgress = (progress) => {
      setDoc((prev) => ({
        ...prev,
        isLoading: true,
        progress,
      }))
    }

    fetchUploadFile(file, setProgress)
      .then(({ file_uuid }) => {
        if (timeOff) {
          onAttachmentChange(file_uuid)
        }

        setDoc((prev) => ({
          ...prev,
          file_uuid,
          isLoading: false,
          success: true,
          progress: 0,
        }))
      })
      .catch(() => {
        setDoc((prev) => ({
          ...prev,
          isLoading: false,
          success: false,
          progress: 0,
        }))
      })
  }

  const handleRemove = () => () => {
    setDoc({})
  }

  const handleRejectReasonChange = (e) => {
    setRejectReason(e.target.value)
  }

  const issueFlagNote = timeOff?.notes.find(
    (note) => note.created_by_role === 'PARTNER' && note.note_type === 'ISSUE_FLAG'
  )

  const renderReviewResult = () => {
    if (timeOff?.status === TIME_OFF_STATUSES.REJECTED) {
      const findEvent = timeOff.events.filter((evt) => evt.event_side === 'COMPANY')[0]
      return (
        <Styled.Review>
          <Typography className="heading_semibold__16 mb-4">Review</Typography>
          <TextArea placeholder="Rejection reason" rows={4} value={findEvent.reject_reason ?? ''} disabled />
        </Styled.Review>
      )
    }
    return null
  }

  const getPrimaryActions = () => {
    if (isDisabled) return []
    if (isReview) {
      return [
        <Button
          size="small"
          disabled={isLoadingReject || !checkAllRequire || !rejectReason}
          loading={isLoadingReject}
          type="button"
          priority="danger"
          onClick={() => onReject(rejectReason)}
          key="reject"
        >
          Reject
        </Button>,
        <Button
          size="small"
          disabled={isLoadingApprove || !checkAllRequire}
          loading={isLoadingApprove}
          type="button"
          onClick={onApprove}
          key="approve"
        >
          Approve
        </Button>,
      ]
    }
    if (isEdit) {
      return [
        <Button
          size="small"
          disabled={isLoadingCancel}
          loading={isLoadingCancel}
          type="button"
          priority="danger"
          onClick={onCancel}
          key="reject"
        >
          Cancel request
        </Button>,
        <Button
          size="small"
          disabled={loading || !checkAllRequire}
          loading={loading}
          form={formId}
          type="submit"
          key="update"
        >
          Update
        </Button>,
      ]
    }
    return [
      <Button
        size="small"
        disabled={loading || !checkAllRequire}
        loading={loading}
        form={formId}
        type="submit"
        key="add"
      >
        Add
      </Button>,
    ]
  }

  return (
    <ModalSide
      title={title}
      onClose={onClose}
      footer={false}
      primaryActions={getPrimaryActions()}
      secondaryActions={[
        <Button priority="secondary" size="small" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <Styled.Request>
        <Typography className="heading_semibold__16 mb-4">Request</Typography>
        <form id={formId} onSubmit={handleSubmit(handleCreateTimeOff)}>
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="contract"
              rules={{ required: 'Field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="CreateTimeOffModal-45866D"
                    {...field}
                    isDisabled={isFetching || isDisabled}
                    isRequired
                    isLoading={isFetching}
                    label="Employee"
                    options={contracts
                      .filter((contract) => contract.contractor_wage_type === 'monthly')
                      .map((contract) => ({
                        value: contract.id,
                        contractor: contract.contract_type === 'contractor' ? ' (Contractor)' : '',
                        label: contract.full_name,
                        countryId: contract.working_country?.id,
                        regionId: contract?.region?.id,
                      }))}
                    getOptionLabel={(option) => `${option.label}${option.contractor}`}
                  />
                )
              }}
            />
            {errors.contract && (
              <Typography className="text_regular__14 color_red">{errors.contract.message}</Typography>
            )}
          </div>
          <div className="row mb-3">
            <div className="col">
              <Controller
                control={control}
                name="leave_reason_type"
                rules={{ required: 'Field is required' }}
                render={({ field }) => {
                  return (
                    <Select
                      data-testid="CreateTimeOffModal-5F92BA"
                      {...field}
                      label="Reason"
                      options={Object.keys(TIME_OFF_LEAVE_TYPES).map((key) => ({
                        value: key,
                        label: TIME_OFF_LEAVE_TYPES[key],
                      }))}
                      isDisabled={isDisabled}
                    />
                  )
                }}
              />
              {errors.leave_reason_type && (
                <Typography className="text_regular__14 color_red">{errors.leave_reason_type.message}</Typography>
              )}
            </div>
          </div>
          <TextArea
            label="Description"
            {...register('comment')}
            rows={3}
            placeholder="Provide more details of the request if needed"
            disabled={isDisabled}
          />
          {errors.comment && <Typography className="text_regular__14 color_red">{errors.comment.message}</Typography>}
          <div className="remo-form-input mt-3 mb-3">
            <div className="toggle-bg">
              <div className="d-flex align-items-center justify-content-between">
                <Typography className="text_medium__14">One day</Typography>
                <Toggle id="is_one_day_absence" {...register('is_one_day_absence')} disabled={isDisabled} />
              </div>
            </div>
          </div>
          <div className="row">
            <Styled.InputPairs>
              <Styled.InputPairsLeft>
                <Controller
                  control={control}
                  name="start_date"
                  rules={{ required: 'Start date is required' }}
                  render={({ field }) => {
                    return <DateInput {...field} isRequired label="Start date" disabled={isDisabled} />
                  }}
                />
                {errors.start_date && (
                  <Typography className="text_regular__14 color_red">{errors.start_date.message}</Typography>
                )}
              </Styled.InputPairsLeft>
              <Styled.Toggle>
                <Toggle id="start_date_is_half_day" {...register('start_date_is_half_day')} disabled={isDisabled} />
                <Typography className="text_medium__14">Start in second half of the day</Typography>
              </Styled.Toggle>
            </Styled.InputPairs>
          </div>

          {!oneDayWatch && (
            <div className="row">
              <Styled.InputPairs>
                <Styled.InputPairsLeft>
                  <Controller
                    control={control}
                    name="end_date"
                    rules={{ required: 'End date is required' }}
                    render={({ field }) => {
                      return <DateInput {...field} label="End date" minDate={starts_at_watch} disabled={isDisabled} />
                    }}
                  />
                  {errors.end_date && (
                    <Typography className="text_regular__14 color_red">{errors.end_date.message}</Typography>
                  )}
                </Styled.InputPairsLeft>
                <Styled.Toggle>
                  <Toggle id="end_date_is_half_day" {...register('end_date_is_half_day')} disabled={isDisabled} />
                  <Typography className="text_medium__14">End in first half of the day</Typography>
                </Styled.Toggle>
              </Styled.InputPairs>
            </div>
          )}

          <div className="remo-form-input mt-1 mb-3">
            <div className="toggle-bg d-flex justify-content-between align-items-center">
              <div>
                <Typography className="text_medium__14">Total time off</Typography>
                <Typography className="text_regular-normal__14 color_text_300">
                  This doesnt include weekends and national holidays
                </Typography>
              </div>

              {isLoadingTotalDayOff ? (
                <div className="edit-avatar__loader">
                  <DotWave size={12} speed={1} color="black" />
                </div>
              ) : (
                <Typography className="text_medium__14">{totalDayOff}</Typography>
              )}
            </div>
          </div>
          {timeOff && isAttachmentLoading ? (
            <div className="d-flex justify-content-center">
              <DotWave />
            </div>
          ) : (
            <AttachmentFormItem
              timeOff={timeOff}
              doc={doc}
              handleRemove={handleRemove()}
              handleUpload={handleUpload}
              handleTimeOffAttachmentDelete={onAttachmentDelete}
            />
          )}
          {!timeOff && (
            <UploadButton
              label="Supporting document"
              addText={doc.description}
              isLoading={doc.isLoading}
              isSuccess={doc.success}
              progress={doc.progress}
              onRemove={handleRemove()}
              onChange={(_file) => handleUpload(_file)}
              accept="application/pdf"
              isDisabled={isDisabled}
            />
          )}
        </form>
      </Styled.Request>
      {renderReviewResult()}
      {timeOff?.leave_type === 'UNPAID' && issueFlagNote && (
        <InfoMessage
          message={`This time off has been flagged as unpaid. The reason: ${issueFlagNote.note}. Please contact help@remofirst.com if you need further assistance.`}
          type="warning"
        />
      )}
      {isReview && (
        <Styled.Review>
          <Typography className="heading_semibold__16 mb-4">Review</Typography>
          <TextArea placeholder="Rejection reason" rows={4} onChange={handleRejectReasonChange} />
        </Styled.Review>
      )}
    </ModalSide>
  )
}
