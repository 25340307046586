import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { Modal } from 'react-bootstrap'

export const ApproveTimesheetModal = ({ onClose, onConfirm, isLoading }) => {
  return (
    <Modal centered show onHide={onClose} animation={false}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">Approve your timesheet?</Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography className="heading_semi__16 color_gray">You are about to approve this timesheet.</Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button priority="secondary" size="small" className="mr-3" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isLoading} priority="primary" size="small" onClick={onConfirm}>
            Confirm approval
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
