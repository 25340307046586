import { AP_TYPE, RECURRENCE_FREQUENCY_TYPES } from '@core/constants'
import { differenceInYears } from 'date-fns'

export const parsePayload = (draft) => {
  const payload = {}
  payload.working_country = draft.working_country
  if (draft.profile) {
    payload.profile = draft.profile
    if (draft.profile.address) {
      payload.profile.address = {
        ...draft.profile.address,
      }
    }
  }
  payload.residency_type = draft.residency_type
  if (draft.job) {
    payload.job = {
      position: draft.job.position,
      position_description: draft.job.position_description,
      requested_starts_at: new Date(draft.job.requested_starts_at),
      ends_at: new Date(draft.job.ends_at),
      department: draft.department,
      is_permanent: draft.job.is_permanent.toString(),
    }
  }
  if (draft.compensation) {
    payload.compensation = {
      currency: draft.compensation.currency,
      yearly_gross_salary: draft.compensation.yearly_gross_salary.toString(),
    }
  }
  return payload
}

export const getCalculationsContractType = (isPermanent) => (isPermanent ? 'permanent' : 'fixed')
export const getAge = (dob) => {
  if (!dob) return 0
  const today = new Date()
  const birthDate = new Date(dob)
  const m = today.getUTCMonth() - birthDate.getUTCMonth()
  let age = today.getUTCFullYear() - birthDate.getUTCFullYear()
  if (m < 0 || (m === 0 && today.getUTCDate() < birthDate.getUTCDate())) {
    age -= 1
  }
  return age
}

export const parseSigningBonus = (amount, currency) => ({
  name: 'Signing bonus',
  ap_type: AP_TYPE.BONUS,
  amount: Number(amount),
  currency: currency.value,
})

export const parseAdditionalPayments = (formValues, type) => {
  return formValues.map(({ title, amount, currency, frequency, hasEnd, isContractual, occurrences }) => {
    const payload = {
      name: title,
      amount: Number(amount),
      currency: currency.value,
      ap_type: type,
      is_contractual: isContractual,
      is_recurrent: frequency.value !== RECURRENCE_FREQUENCY_TYPES.ONE_TIME,
      is_indefinite: !hasEnd,
    }
    if (frequency.value !== RECURRENCE_FREQUENCY_TYPES.ONE_TIME) {
      payload.recurrence_frequency = frequency.value
    }
    if (hasEnd) {
      payload.occurences = Number(occurrences) // typo on BE
    }
    return payload
  })
}
