import PageTitle from '@atoms/PageTitle/PageTitle'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import { useState } from 'react'

import { AllTimesheets } from './timesheets/AllTimesheets'
import { ApprovedTimesheets } from './timesheets/ApprovedTimesheets'
import { PendingTimesheets } from './timesheets/PendingTimesheets'
import { RejectedTimesheets } from './timesheets/RejectedTimesheets'

export const TimesheetsTab = () => {
  const [tab, setTab] = useState(1)

  const onTabChange = (id) => {
    setTab(id)
  }

  return (
    <>
      <PageTitle>Timesheets</PageTitle>
      <Tabs className="mt-5" selectedTab={tab} onTabChange={onTabChange}>
        <Tab tabId={1} title="All">
          <AllTimesheets />
        </Tab>
        <Tab tabId={2} title="Pending">
          <PendingTimesheets />
        </Tab>
        <Tab tabId={3} title="Approved">
          <ApprovedTimesheets />
        </Tab>
        <Tab tabId={4} title="Rejected">
          <RejectedTimesheets />
        </Tab>
      </Tabs>
    </>
  )
}
