export const parseDetailsToConfig = (probationDetails, startDate, endDate, isPermanent) => {
  const defaultConfig = {
    applicable: false,
    min: 0,
    max: 0,
    suggested: 0,
    note: '',
  }

  if (!probationDetails) {
    return {
      ...defaultConfig,
      noProbation: true,
    }
  }
  let config = defaultConfig

  const {
    calculation_type,
    country,
    fixed_contract_is_applicable,
    fixed_contract_maximum_day,
    fixed_contract_minimum_day,
    fixed_contract_note,
    fixed_contract_suggested_day,
    permanent_contract_is_applicable,
    permanent_contract_maximum_day,
    permanent_contract_minimum_day,
    permanent_contract_note,
    permanent_contract_suggested_day,
    probation_type,
    segments,
  } = probationDetails
  if (isPermanent) {
    config = {
      applicable: permanent_contract_is_applicable,
      min: permanent_contract_minimum_day,
      max: permanent_contract_maximum_day,
      note: permanent_contract_note,
      suggested: permanent_contract_suggested_day,
    }
  } else {
    const contractDuration = Math.floor(
      (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24)
    )
    if (probation_type === 'standard') {
      config = {
        applicable: fixed_contract_is_applicable,
        min: Math.min(fixed_contract_minimum_day, contractDuration),
        max: Math.min(fixed_contract_maximum_day, contractDuration),
        note: fixed_contract_note,
        suggested: fixed_contract_suggested_day,
      }
    }
    if (probation_type === 'specific_calculation') {
      config = {
        applicable: fixed_contract_is_applicable,
        min: fixed_contract_minimum_day,
        max: Math.min(fixed_contract_maximum_day, Math.floor(contractDuration / Number(calculation_type.slice(-1)))),
        note: fixed_contract_note,
        suggested: Math.min(
          fixed_contract_maximum_day,
          Math.floor(contractDuration / Number(calculation_type.slice(-1)))
        ),
      }
    }
    if (probation_type === 'specific_to_duration_of_fixed_contract') {
      const durationInMonths = Math.floor(contractDuration / 30)
      let min = 0
      let max = 0

      segments.forEach(({ max_period_in_months, min_period_in_months, probation_days }, index) => {
        if (durationInMonths >= min_period_in_months && durationInMonths <= max_period_in_months) {
          max = probation_days
          if (index !== 0) {
            min = segments[index - 1].probation_days
          }
        }
      })
      config = {
        applicable: fixed_contract_is_applicable,
        min,
        max,
        note: fixed_contract_note,
        suggested: fixed_contract_suggested_day,
      }
    }
  }
  if (typeof config.min !== 'number' || typeof config.max !== 'number' || !config.max) {
    return defaultConfig
  }
  return config
}
