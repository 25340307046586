import 'react-circular-progressbar/dist/styles.css'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import { stepChecked } from '@core/icons/icons'
import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import styled, { css } from 'styled-components'

const Root = styled.div`
  border-bottom: 2px solid #f3f3f3;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  svg rect {
    fill: ${(props) => (props.completed ? '#40b84c' : '#ccc ')};
  }

  &:last-child {
    border: 0;
  }
`

const Content = styled.div`
  flex: 1;
`

const Status = styled.div`
  width: 20px;
  height: 20px;
`

const Title = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: #101828;
`
const Description = styled.div`
  font-size: 0.875rem;
  color: #878787;
`

const CustomIcon = styled.div`
  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.6;
          filter: grayscale(100%);
        `
      : ''}
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`

const ActionsPlaceholder = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${(props) => (props.disabled ? '#878787' : '#40b84c')};
`

const getStatus = (started, completed) => {
  let value = 0

  if (started) value = 50
  if (completed) value = 100

  return (
    <CircularProgressbar
      value={value}
      strokeWidth={12}
      styles={{
        path: {
          stroke: `#40b84c`,
        },
      }}
    />
  )
}

const getActions = (disabled, disabledText, actions, actionsPlaceholderText) => {
  if (disabled) return <ActionsPlaceholder disabled={disabled}>{disabledText}</ActionsPlaceholder>
  if (actionsPlaceholderText) return <ActionsPlaceholder>{actionsPlaceholderText}</ActionsPlaceholder>
  if (actions && actions.length) return <Actions>{actions.map((item) => item)}</Actions>
  return null
}

export const CardStepperStep = ({
  title,
  icon,
  description,
  styleClass,
  actions,
  actionsPlaceholderText,
  disabledText,
  disabled = false,
  completed = false,
  started = false,
}) => (
  <Root disabled={disabled} className={styleClass} completed={completed}>
    {<CustomIcon disabled={disabled}>{icon}</CustomIcon> || <Icon icon={stepChecked} />}
    <Content>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Content>
    {getActions(disabled, disabledText, actions, actionsPlaceholderText)}
  </Root>
)
