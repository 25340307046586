import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import UploadButton from '@atoms/UploadButton'
import { attachFile, deleteIcon } from '@core/icons/icons'

import { Styled } from './time-off-modal/time-off-modal.styles'

function AttachmentFormItem({ timeOff, doc, handleUpload, handleRemove, handleTimeOffAttachmentDelete }) {
  if (!timeOff) return false

  const { attached_document } = timeOff
  const { description, isLoading, success, progress } = doc

  if (attached_document) {
    return (
      <div className="d-flex flex-row justify-content-between align-items-center mt-3">
        <Typography as="span" className="text_medium__14">
          <Icon icon={attachFile} />
          <a href={timeOff?.attached_document} target="_blank" rel="noreferrer">
            Attached document
          </a>
        </Typography>
        <Styled.DeleteButton
          data-testid="TimeOffModal-delete-attachment-button"
          role="button"
          onClick={handleTimeOffAttachmentDelete}
        >
          Remove current document
          <Icon icon={deleteIcon} className="mr-2" />
        </Styled.DeleteButton>
      </div>
    )
  }

  return (
    <UploadButton
      label="Edit supporting document"
      addText={description}
      isLoading={isLoading}
      isSuccess={success}
      progress={progress}
      onRemove={handleRemove}
      onChange={(_file) => handleUpload(_file)}
      accept="application/pdf"
    />
  )
}

export default AttachmentFormItem
