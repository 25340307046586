import React from 'react'

export const SuccessMassImport = () => {
  return (
    <svg
      data-testid="success-mass-import.component-8A5AB6"
      width="164"
      height="165"
      viewBox="0 0 164 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M71.7509 139.911C93.2619 139.911 110.701 120.674 110.701 96.861C110.701 73.048 93.2619 53.8112 71.7509 53.8112C50.24 53.8112 32.8008 73.048 32.8008 96.861C32.8008 120.674 50.24 139.911 71.7509 139.911Z"
        fill="#EFEFEF"
      />
      <path d="M139.4 67.7708L155.451 53.8112L164 57.124L146.946 70.2112L139.4 67.7708Z" fill="#D5DDEA" />
      <path d="M28.6992 44.2593L48.4708 29.2111L53.2992 35.7119L35.1664 49.7111L28.6992 44.2593Z" fill="#D5DDEA" />
      <path d="M20.5 97.3657L4.11599 103.011L0 97.3657L16.7037 90.7111L20.5 97.3657Z" fill="#989FB0" />
      <path d="M90.1992 30.5513L104.917 45.6111L110.699 39.4004L96.8073 25.1111L90.1992 30.5513Z" fill="#989FB0" />
      <path
        d="M109.708 81.9888C107.449 72.1244 101.414 63.7827 93.3231 58.8076C88.1789 55.6339 82.2043 53.8112 75.8449 53.8112C56.625 53.8112 41 70.366 41 90.7058C41 94.7051 41.5975 98.5651 42.7316 102.168C45.7189 111.85 52.3922 119.784 60.9996 124.073C65.5059 126.357 70.5387 127.611 75.8551 127.611C95.075 127.611 110.7 111.056 110.7 90.7058C110.7 87.7036 110.356 84.7765 109.708 81.9888ZM75.8551 115.409C74.3867 115.409 72.9387 115.259 71.5412 114.981C61.6376 112.997 53.901 104.355 52.7162 93.6222C52.615 92.6679 52.5542 91.6922 52.5542 90.7058C52.5542 77.0995 63.0148 66.0236 75.8652 66.0236C78.4879 66.0236 81.0195 66.4846 83.3891 67.3424C92.5636 70.6555 99.1863 79.8765 99.1863 90.7058C99.1863 90.8452 99.1863 90.9631 99.1762 91.1025C98.9837 104.537 88.6042 115.399 75.8753 115.399L75.8551 115.409Z"
        fill="url(#paint0_linear_1090_12773)"
      />
      <path
        d="M32.8008 82.1263L44.9165 62.0422L71.1889 82.1108C71.1889 82.1108 100.976 52.9639 124.608 45.6111L131.201 71.4625C131.201 71.4625 101.414 78.4111 73.489 111.211L32.817 82.1263H32.8008Z"
        fill="#40b84c"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1090_12773"
          x1="40.968"
          y1="90.719"
          x2="110.713"
          y2="90.719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  )
}
