import Button from '@atoms/Button/Button'
import { Info } from '@components/info/info.component'
import { AppContext } from '@core/context'
import { getAddress } from '@core/utils'
import { patchContractById } from '@store/contracts'
import { isObject } from 'lodash'
import moment from 'moment'
import React, { useContext, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { CompanyInformationEditForm } from './forms/company-information-edit-form/company-information-edit-form.component'
import { CompensationForm } from './forms/compensation-edit-form/compensation-edit-form.component'
import { ContractorDetailEditForm } from './forms/contractor-detail-edit-form/contractor-detail-edit-form.component'
import { JobDetailsEditForm } from './forms/job-details-edit-form/job-details-edit-form.component'
import { SigneeDetailsEditForm } from './forms/signee-details-edit-form/signee-details-edit-form.component'
import { Container, Content } from './review.styles'

const contractorDetailsTitle = 'Contractor details'
const jobDetailsTitle = 'Job details'
const compensationTitle = 'Compensation'
const companyInformationTitle = 'Company information'
const signeeDetailsTitle = 'Signee details'

export const Review = ({ isAorCompany, signers, contractor, onNext, setSigners, loading }) => {
  const { profile } = useContext(AppContext)
  const queryClient = useQueryClient()
  const contractorDetailsEditOpen = useBoolean(false)
  const jobDetailsEditOpen = useBoolean(false)
  const compensationEditOpen = useBoolean(false)
  const companyInformationEditOpen = useBoolean(false)
  const signeeDetailsnEditOpen = useBoolean(false)
  const isBiWeeklyFrequency = contractor?.compensation?.contractor_payment_frequency === 'biweekly'

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => patchContractById(payload.id, payload.data),
    onSuccess: (data) => {
      contractorDetailsEditOpen.setFalse()
      jobDetailsEditOpen.setFalse()
      compensationEditOpen.setFalse()
      companyInformationEditOpen.setFalse()
      if (data.data) {
        queryClient.setQueryData(['detailEmployee', contractor.id], data.data)
      }
    },
  })

  const contractorDetails = useMemo(
    () => ({
      firstName: contractor?.profile?.first_name,
      middleName: contractor?.profile?.middle_name || '-',
      lastName: contractor?.profile?.last_name,
      email: contractor?.profile?.email,
      workingCountry: contractor?.working_country?.name,
      homeState: contractor?.profile?.home_address?.state,
    }),
    [contractor]
  )
  const contractorDetailsData = useMemo(
    () => ({
      ...contractorDetails,
      workingCountryId: contractor?.working_country?.id,
    }),
    [contractor]
  )

  const jobDetails = useMemo(
    () => ({
      jobTitle: contractor?.job.position,
      jobDescription: contractor?.job?.position_description,
      department: contractor?.department,
    }),
    [contractor]
  )

  const compensation = useMemo(
    () => ({
      contractTermName: contractor?.job?.ends_at ? 'Fixed term' : 'Permanent',
      startDate: contractor?.job?.requested_starts_at || '-',
      endDate: contractor?.job?.ends_at || '-',
      ...(!isBiWeeklyFrequency && { rateType: contractor?.compensation?.contractor_wage_type }),
      ...(isBiWeeklyFrequency && { cycleStartDate: contractor.compensation.cycle_start_at || '-' }),
      contractCurrency: contractor?.compensation?.currency?.name,
      receivingCurrency: contractor?.compensation?.receiving_currency?.name,
      contractorRate: contractor?.compensation?.contractor_rate,
      paymentFrequency: contractor?.compensation?.contractor_payment_frequency || '-',
    }),
    [contractor, isBiWeeklyFrequency]
  )
  const companyInformation = useMemo(
    () => ({
      companyName: profile.name,
      country: profile?.legal_address?.living_country?.name,
      registeredAddress: getAddress(profile?.legal_address),
    }),
    [profile]
  )

  const signeeDetails = useMemo(
    () =>
      signers?.length
        ? {
            name: signers[0].name,
            jobTitle: signers[0].role_name,
            email: signers[0].email_address,
          }
        : null,
    [signers]
  )
  const compensationData = useMemo(
    () => ({
      ...compensation,
      contractCurrencyId: contractor?.compensation?.currency.id,
      estimated_working_hours: contractor?.compensation?.estimated_working_hours,
      contractTerm: contractor?.job?.is_permanent ? 'permanent' : 'fixed',
    }),
    [contractor]
  )

  const handleContractorDetailSubmit = (formValues) => {
    mutate({
      id: contractor.id,
      data: {
        profile: {
          first_name: formValues.firstName,
          last_name: formValues.lastName,
          middle_name: formValues.middleName,
          email: formValues.email,
          home_address: {
            state: formValues.homeState,
          },
        },
        working_country: isObject(formValues.workingCountryId)
          ? formValues.workingCountryId.value
          : formValues.workingCountryId,
        compensation: {
          ...contractor.compensation,
          receiving_currency: contractor.compensation.receiving_currency.id,
          currency: contractor.compensation.currency.id,
          working_country_currency: contractor.compensation.working_country_currency.id,
        },
      },
    })
  }

  const handleJobDetailsSubmit = ({ jobTitle, jobDescription, department }) => {
    mutate({
      id: contractor.id,
      data: {
        department,
        job: {
          position: jobTitle?.label || jobTitle,
          position_description: jobDescription,
          starts_at: contractor.job?.starts_at,
        },
        compensation: {
          ...contractor.compensation,
          receiving_currency: contractor.compensation.receiving_currency.id,
          currency: contractor.compensation.currency.id,
          working_country_currency: contractor.compensation.working_country_currency.id,
        },
      },
    })
  }

  const handleCompensationSubmit = (formValues) => {
    const isMilestoneBased = formValues.rateType === 'milestone'
    const isBiweeklyFrequency = formValues.paymentFrequency === 'biweekly'

    mutate({
      id: contractor.id,
      data: {
        compensation: {
          ...(!isMilestoneBased && { contractor_rate: Number(formValues.contractorRate) }),
          ...(!isBiweeklyFrequency && {
            estimated_working_hours: Number(formValues.estimated_working_hours),
            contractor_wage_type: formValues.rateType,
          }),
          ...(isBiweeklyFrequency && { cycle_start_at: moment(formValues.cycleStartDate).format('YYYY-MM-DD') }),
          ...(!isMilestoneBased && { contractor_payment_frequency: formValues.paymentFrequency }),
          currency: +formValues.contractCurrencyId,
        },
        job: {
          ...contractor.job,
          occupation: contractor.job?.occupation?.id,
          starts_at: moment(formValues.startDate).format('YYYY-MM-DD'),
          requested_starts_at: moment(formValues.startDate).format('YYYY-MM-DD'),
          is_permanent: formValues.contractTerm === 'permanent',
          ends_at: formValues.contractTerm === 'fixed' ? moment(formValues.endDate).format('YYYY-MM-DD') : null,
        },
      },
    })
  }

  const onCloseSigneeModal = (formValues) => {
    if (formValues) setSigners([formValues])
    signeeDetailsnEditOpen.setFalse()
  }

  return (
    <>
      <Container>
        <Content>
          <Info
            title={contractorDetailsTitle}
            data={contractorDetails}
            actions={[
              <Button
                data-testid="review-5A87FA"
                key={1}
                type="button"
                size="small"
                priority="secondary"
                onClick={contractorDetailsEditOpen.setTrue}
              >
                Edit
              </Button>,
            ]}
          />
          <Info
            title={jobDetailsTitle}
            data={jobDetails}
            actions={[
              <Button
                data-testid="review-2BCD61"
                key={2}
                type="button"
                size="small"
                priority="secondary"
                onClick={jobDetailsEditOpen.setTrue}
              >
                Edit
              </Button>,
            ]}
          />
          <Info
            title={compensationTitle}
            data={compensation}
            actions={[
              <Button
                data-testid="review-D0695B"
                key={3}
                type="button"
                size="small"
                priority="secondary"
                onClick={compensationEditOpen.setTrue}
              >
                Edit
              </Button>,
            ]}
          />
          {!isAorCompany && (
            <Info
              title={companyInformationTitle}
              data={companyInformation}
              actions={[
                <Button
                  data-testid="review-3C3903"
                  key={4}
                  type="button"
                  size="small"
                  priority="secondary"
                  onClick={companyInformationEditOpen.setTrue}
                >
                  Edit
                </Button>,
              ]}
            />
          )}
          {!isAorCompany && signeeDetails && (
            <Info
              title={signeeDetailsTitle}
              data={signeeDetails}
              actions={[
                <Button
                  data-testid="review-A2253A"
                  key={5}
                  type="button"
                  size="small"
                  priority="secondary"
                  onClick={signeeDetailsnEditOpen.setTrue}
                >
                  Edit
                </Button>,
              ]}
            />
          )}
        </Content>
        <Button data-testid="review-80DBEE" type="button" onClick={onNext} loading={loading}>
          E-sign and Send
        </Button>
      </Container>
      {contractorDetailsEditOpen.value && (
        <ContractorDetailEditForm
          title={contractorDetailsTitle}
          data={contractorDetailsData}
          onSubmit={handleContractorDetailSubmit}
          onClose={contractorDetailsEditOpen.setFalse}
          isLoading={isLoading}
        />
      )}
      {jobDetailsEditOpen.value && (
        <JobDetailsEditForm
          title={jobDetailsTitle}
          data={jobDetails}
          onSubmit={handleJobDetailsSubmit}
          onClose={jobDetailsEditOpen.setFalse}
          isLoading={isLoading}
        />
      )}
      {compensationEditOpen.value && (
        <CompensationForm
          title={compensationTitle}
          data={compensationData}
          onSubmit={handleCompensationSubmit}
          onClose={compensationEditOpen.setFalse}
          isLoading={isLoading}
          workingCountryId={contractor?.working_country?.id}
        />
      )}
      {companyInformationEditOpen.value && (
        <CompanyInformationEditForm title={companyInformationTitle} onClose={companyInformationEditOpen.setFalse} />
      )}
      {signeeDetailsnEditOpen.value && (
        <SigneeDetailsEditForm signer={signers[0]} title={signeeDetailsTitle} onClose={onCloseSigneeModal} />
      )}
    </>
  )
}
