import Step from '@atoms/Stepper/Step'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import Typography from '@atoms/Typography/Typography'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'

import { ConfirmTimesheetUpload } from './ConfirmTimesheetUpload'
import { IdentifyColumns } from './IdentifyColumns'
import { UploadCSV } from './UploadCSV'
import { ValidateTimesheet } from './ValidateTimesheet'

const step1 = 'Upload CSV'
const step2 = 'Identify columns'
const step3 = 'Validate timesheet'
const step4 = 'Confirm upload'

export const UploadTimesheetsModal = ({ onClose }) => {
  const [activeStep, setStep] = useState(1)
  const [payload, setPayload] = useState({})

  const handleBack = () => {
    setStep((prev) => prev - 1)
  }

  const handleNext = (formValues) => {
    if (formValues) {
      setPayload((prev) => ({
        ...prev,
        ...formValues,
      }))
    }
    setStep((prev) => prev + 1)
  }

  const handleReset = () => {
    setStep(1)
    setPayload({})
    payload.handleReset()
  }

  return (
    <Modal show onClose={onClose} fullscreen>
      <Modal.Body className="p-0">
        <Stepper
          activeStep={activeStep}
          title="Upload timesheet"
          description={<Typography className="mt-4">Please fill in the details of your timesheets.</Typography>}
        >
          <Step step="1" title={step1} setStep={setStep}>
            <StepContent title={step1} onClose={onClose}>
              <UploadCSV onNext={handleNext} />
            </StepContent>
          </Step>

          <Step step="2" title={step2} setStep={setStep}>
            <StepContent title={step2} onClose={onClose} onBack={handleBack}>
              <IdentifyColumns payload={payload} onNext={handleNext} onReUpload={handleReset} />
            </StepContent>
          </Step>

          <Step step="3" title={step3} setStep={setStep}>
            <StepContent title={step3} onClose={onClose} onBack={handleBack}>
              <ValidateTimesheet payload={payload} onNext={handleNext} onReUpload={handleReset} />
            </StepContent>
          </Step>
          <Step step="3" title={step4} setStep={setStep}>
            <StepContent title={step4} onClose={onClose} onBack={handleBack}>
              <ConfirmTimesheetUpload payload={payload} onClose={onClose} />
            </StepContent>
          </Step>
        </Stepper>
      </Modal.Body>
    </Modal>
  )
}
