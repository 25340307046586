import styled from 'styled-components'

const Container = styled.div`
  width: 540px;
`

const LinkButton = styled.button`
  border: 0;
  margin: 0 0 0 2px;
  padding: 0;
  color: #137547;
  text-decoration: underline;
`

export const Styled = {
  Container,
  LinkButton,
}
