import { TIMESHEET_STATE } from '@core/constants'

export const parseStatus = (status) => {
  switch (status) {
    case TIMESHEET_STATE.REJECTED:
      return {
        label: 'To revise',
        color: '#6D6D6C',
      }
    case TIMESHEET_STATE.PENDING:
      return {
        label: 'Pending',
        color: '#FFA530',
      }
    case TIMESHEET_STATE.APPROVED:
      return {
        label: 'Approved',
        color: '#2DB77B',
      }
    case TIMESHEET_STATE.PAID:
      return {
        label: 'Paid',
        color: '#2DB77B',
      }
    case TIMESHEET_STATE.PENDING_INVOICE:
      return {
        label: 'Pending invoice',
        color: '#FFA530',
      }
    case TIMESHEET_STATE.PENDING_PAYMENT_APPROVAL:
      return {
        label: 'Pending payment',
        color: '#FFA530',
      }
    case TIMESHEET_STATE.DRAFT:
      return {
        label: 'Draft',
        color: '#497CF6',
      }
    default:
      return {
        label: status,
        color: '#ADADAD',
      }
  }
}

export const TimesheetStatus = ({ status }) => {
  const { label, color } = parseStatus(status)
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color,
        borderRadius: '50px',
        padding: '4px 12px',
        gap: 8,
        width: 'fit-content',
      }}
    >
      <span style={{ color: '#fff', fontWeight: 600, fontSize: 12 }}>{label}</span>
    </div>
  )
}
