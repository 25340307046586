import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'

export const PaymentMethodInfoNotRemove = ({ modalState }) => {
  return (
    <Modal show={modalState.value} onHide={modalState.setFalse} centered animation={false}>
      <Modal.Header closeButton className="mb-0">
        <Modal.Title>
          <Typography className="heading_semibold__24">Cannot remove payment method</Typography>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Typography className="text_regular__14 mt-3 color_grey">
          We are sorry that you cannot remove this payment method as it is the only payment method on your account and
          you have outstanding invoices to settle first.
        </Typography>
        <Typography className="text_regular__14 color_grey mt-3">
          Please contact{' '}
          <a
            data-testid="payment-method-info-not-remove.component-863364"
            style={{ textDecoration: 'none', color: '#40b84c' }}
            href="mailto: finance@remofirst.com"
          >
            finance@remofirst.com
          </a>{' '}
          for further assistance.
        </Typography>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="payment-method-info-not-remove.component-EF0B83"
          type="submit"
          size="small"
          priority="primary"
          onClick={modalState.setFalse}
        >
          Understood
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
