import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { formatMinutesToHours } from '@core/utils'
import moment from 'moment'

export const TIMESHEET_TABLE_FIELDS = [
  {
    title: 'Start time',
    type: 'custom',
    maxWidth: 400,
    minWidth: 300,
    render: (item) => {
      return (
        <Input
          style={{ borderRadius: 8, height: 38, margin: 0 }}
          disabled
          value={moment(item.start_time, 'HH:mm:ss').format('HH:mm')}
        />
      )
    },
  },
  {
    title: 'Finish time',
    type: 'custom',
    maxWidth: 400,
    minWidth: 300,
    render: (item) => {
      return (
        <Input
          style={{ borderRadius: 8, height: 38, margin: 0 }}
          disabled
          value={moment(item.end_time, 'HH:mm:ss').format('HH:mm')}
        />
      )
    },
  },
  {
    title: 'Task',
    type: 'custom',
    maxWidth: 400,
    minWidth: 300,
    render: (item) => {
      return <Select isDisabled value={item.task_name} options={[{ label: item.task_name, value: item.task_name }]} />
    },
  },
  {
    title: 'Hours worked',
    type: 'custom',
    maxWidth: 400,
    minWidth: 300,
    render: (item) => {
      return <Typography>{formatMinutesToHours(item.total_minutes_spent)}</Typography>
    },
  },
]

export const TASK_NAME_ON_TIMESHEET = {
  work: 'Work',
  break: 'Break',
  meeting: 'Meeting',
  on_call: 'On a call',
  overtime: 'Overtime',
  other: 'Other',
}
