import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import Typography from '@atoms/Typography/Typography'
import { bulkUploadTimesheets } from '@services/timesheets.service'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import { TimesheetsErrorModal } from './TimesheetsErrorModal'

export const ConfirmTimesheetUpload = ({ payload, onClose }) => {
  const [error, setError] = useState(null)
  const isValidationError = useBoolean(false)
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const bulkUploadTimesheetsMutation = useMutation({
    mutationFn: bulkUploadTimesheets,
    onSuccess: (res) => {
      if (res.errors) {
        setError(res.errors)
        isValidationError.setTrue()
      } else {
        setError(null)
        onClose()
        navigate('/pages/timesheets')
        toast.success('Timesheets successfully uploaded')
      }
    },
  })

  const handleConfirmUpload = () => {
    const timesheetPayload = {
      start_period: payload.startDate,
      end_period: payload.endDate,
      timesheet_fields: payload.timesheetFields,
    }
    bulkUploadTimesheetsMutation.mutate(timesheetPayload)
  }

  const handleModalClose = () => {
    setError(null)
    isValidationError.setFalse()
  }

  return (
    <>
      <div className="d-flex flex-column gap-4">
        <Typography className="heading_semibold__20 mb-5">Confirm timesheet upload</Typography>
        <form onSubmit={handleSubmit(handleConfirmUpload)}>
          <div>
            <Controller
              control={control}
              name="confirm"
              rules={{
                required: 'Confirmation is required',
              }}
              render={({ field }) => {
                return (
                  <Checkbox
                    label="I confirm that I have reviewed and approve the imported timesheet. "
                    description="Remofirst takes no responsibility for any incorrect data that may have been added during the upload process."
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )
              }}
            />
            {errors.confirm && (
              <Typography className="text_regular__14 color_red mt-2">{errors.confirm.message}</Typography>
            )}
          </div>
          <div className="d-flex pb-5 justify-content-center gap-4 mt-5">
            <Button type="submit" className="align-self-end" disabled={bulkUploadTimesheetsMutation.isLoading}>
              Confirm timesheet upload
            </Button>
          </div>
        </form>
      </div>
      {isValidationError.value && <TimesheetsErrorModal error={error} onClose={handleModalClose} />}
    </>
  )
}
