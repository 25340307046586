import { useState } from 'react'

export const useClientPagination = <T>(data?: T[], limit = 10, initialPage = 1) => {
  const [currentPage, setCurrentPage] = useState(initialPage)

  const totalPages = data ? Math.ceil(data.length / limit) : 0

  const currentData = data ? data.slice((currentPage - 1) * limit, currentPage * limit) : null

  const goToPage = (page: number) => {
    const pageNumber = Math.max(1, Math.min(page, totalPages))
    setCurrentPage(pageNumber)
  }
  return {
    currentData,
    currentPage,
    totalPages,
    goToPage,
  }
}
