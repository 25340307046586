/* eslint-disable consistent-return */
/* eslint-disable default-case */
import { TEMPLATE_TYPES } from '@core/constants'
import AgreementDetail from '@features/agreements/agreement-detail/agreement-detail.component'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React from 'react'
import { MdOutlineInsertDriveFile } from 'react-icons/md'
import styled from 'styled-components'

const FileIcon = styled(MdOutlineInsertDriveFile)`
  color: #40b84c;
  margin-right: 8px;
`

export const transformTemplates = (templatesResponse) => {
  if (!templatesResponse || !templatesResponse.results) return []
  return templatesResponse.results.map((item) => {
    const { id, title, status, modified_at } = item
    return {
      id,
      name: (
        <>
          <FileIcon />
          {title}
        </>
      ),
      status,
      updated: moment(modified_at).format('DD MMM YYYY, HH:mm'),
      original: item,
    }
  })
}

const agreementDocumentType = (type) => {
  switch (type) {
    case TEMPLATE_TYPES.JOB_CONTRACT:
      return 'Employment agreement'
    case TEMPLATE_TYPES.APPENDIX:
      return 'MSA appendix'
    case TEMPLATE_TYPES.MSA:
      return 'MSA'
    case TEMPLATE_TYPES.SALARY_CHANGE:
      return 'Salary change'
    case TEMPLATE_TYPES.CONTRACT_TERMINATION:
      return 'Contract termination'
    case TEMPLATE_TYPES.CONTRACTOR_EMPLOYMENT:
      return 'Contractor employment'
    case TEMPLATE_TYPES.AOR_CONTRACT:
      return 'AOR agreement'
    default:
      return ''
  }
}

const getCompanyEmployeeName = ({
  agreement_document_type,
  contractor_employment_agreement,
  compliance_agreement,
  job_agreement,
  salary_change_agreement,
}) => {
  switch (agreement_document_type) {
    case TEMPLATE_TYPES.APPENDIX:
    case TEMPLATE_TYPES.MSA:
      return '-'

    case TEMPLATE_TYPES.CONTRACTOR_EMPLOYMENT:
      return contractor_employment_agreement?.contract?.full_name || '-'

    case TEMPLATE_TYPES.COMPLIANCE:
      return compliance_agreement?.contract?.full_name || '-'

    case TEMPLATE_TYPES.JOB_CONTRACT:
      return job_agreement?.contract?.full_name || '-'

    case TEMPLATE_TYPES.SALARY_CHANGE:
      return salary_change_agreement?.salary_change?.contract?.full_name || '-'
  }
}

export const transformAgreements = (agreementsResponse, agreementDetail, isLoading) => {
  if (!agreementsResponse || !agreementsResponse.results) return []
  return agreementsResponse.results.map((item) => ({
    id: item.id,
    signature: item.signature,
    name: (
      <>
        <FileIcon />
        {item.title}
      </>
    ),
    company: item?.job_agreement?.contract?.company.name,
    // employee: getCompanyEmployeeName(item),
    status: item.state,
    pending: item.awaiting_signature_count,
    agreement_document_type: agreementDocumentType(item.agreement_document_type),
    created: moment(item.created_at).format('DD MMM YYYY, HH:mm'),
    original: item,
    collapseContent: isLoading ? (
      <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: 200 }}>
        <DotWave size={32} speed={1} color="black" />
      </div>
    ) : (
      <AgreementDetail data={agreementDetail} />
    ),
  }))
}
