import Button from '@atoms/Button/Button'
import List from '@atoms/List/List'
import Typography from '@atoms/Typography/Typography'
import { InfoMessage } from '@components/info-message/info-message.component'
import { CONTRACT_TYPE, EMPLOYEE_STATES } from '@core/constants'
import { useToast } from '@core/hooks/useNotification'
import {
  getPaidBereavementLeaves,
  getPaidParental,
  getPaidSickLeaves,
  getPaidTimeOff,
} from '@pages/employeeDetailPage/mock'
import { getContractsPaidLeaves, postContractsPaidLeaves } from '@services/contract.service'
import { fetchCountryPaidLeavesById } from '@services/countries.service'
import { DotWave } from '@uiball/loaders'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import { PaidBereavementModal } from './PaidBereavementModal'
import { PaidParentalModal } from './PaidParentalModal'
import { PaidSickLeaveModal } from './PaidSickLeaveModal'
import { PaidTimeOffModal } from './PaidTimeOffModal'
import { TimeOffCarryOverForm } from './TimeOffCarryOverForm'

const Container = styled.div`
  border: 2px solid #f3f3f3;
  border-radius: 8px;
  height: 100%;
  padding: 16px;
  padding-bottom: 0;
`

export default function TimeOffEmployee({ employee, refetchEmployee }) {
  const isPtoEditable = employee.contract_type === CONTRACT_TYPE.CONTRACTOR || employee.state !== EMPLOYEE_STATES.ACTIVE

  const { data: countryTimeoffPolicyData, isLoading: isCountryTimeoffPolicyLoading } = useQuery(
    [fetchCountryPaidLeavesById.key, employee.working_country.id],
    {
      queryFn: () => fetchCountryPaidLeavesById(employee.working_country.id),
    }
  )

  const isCarryOverAllowedByCountry = countryTimeoffPolicyData?.allow_carryover

  const { successAlert } = useToast()

  const timeOffOpen = useBoolean(false)
  const sickOpen = useBoolean(false)
  const parentalOpen = useBoolean(false)
  const bereavementOpen = useBoolean(false)

  const { data, refetch, isLoading } = useQuery(['getContractsPaidLeaves', employee], {
    queryFn: () => getContractsPaidLeaves(employee.id),
  })

  const updateContractsPaidLeaves = useMutation({
    mutationFn: (payload) => postContractsPaidLeaves(employee.id, payload),
    onSuccess: () => {
      refetch()
      successAlert('Successfully updated!')
      timeOffOpen.setFalse()
      parentalOpen.setFalse()
      sickOpen.setFalse()
      bereavementOpen.setFalse()
    },
  })

  if (isLoading || isCountryTimeoffPolicyLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <DotWave />
      </div>
    )
  }

  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <Typography className="heading_semibold__20 color_black">Time-off</Typography>
        </div>
        {data && (
          <div className="row justify-content-between">
            {data.leave_days.paid_leave && (
              <div className="col-6 mb-3">
                <Container>
                  <div className="d-flex justify-content-between">
                    <Typography className="heading_semibold__20 color_black mb-4">Paid time - off days</Typography>
                    {isPtoEditable && (
                      <Button
                        data-testid="TimeOffEmployee-AAAC00"
                        size="small"
                        priority="secondary"
                        onClick={timeOffOpen.setTrue}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                  <List
                    hideLastBorder
                    textPosition="horizontal"
                    lists={getPaidTimeOff(data.leave_days.paid_leave).map((v) => ({
                      ...v,
                      value: v.value?.join(', '),
                    }))}
                  />
                </Container>
              </div>
            )}
            {data.leave_days.paid_sick_leave && (
              <div className="col-6 mb-3">
                <Container>
                  <div className="d-flex justify-content-between">
                    <Typography className="heading_semibold__20 color_black mb-4">Paid sick leave</Typography>
                    {isPtoEditable && (
                      <Button
                        data-testid="TimeOffEmployee-CBBA80"
                        size="small"
                        priority="secondary"
                        onClick={sickOpen.setTrue}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                  <List
                    hideLastBorder
                    textPosition="horizontal"
                    lists={getPaidSickLeaves(data.leave_days.paid_sick_leave).map((v) => ({
                      ...v,
                      value: v.value?.join(', '),
                    }))}
                  />
                </Container>
              </div>
            )}
            {data.leave_days.paid_bereavement_leave && (
              <div className="col-6 mb-3">
                <Container>
                  <div className="d-flex justify-content-between">
                    <Typography className="heading_semibold__20 color_black mb-4">Paid bereavement leave</Typography>
                    {isPtoEditable && (
                      <Button
                        data-testid="TimeOffEmployee-D8CCBE"
                        size="small"
                        priority="secondary"
                        onClick={bereavementOpen.setTrue}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                  <List
                    hideLastBorder
                    textPosition="horizontal"
                    lists={getPaidBereavementLeaves(data.leave_days.paid_bereavement_leave).map((v) => ({
                      ...v,
                      value: v.value?.join(', '),
                    }))}
                  />
                </Container>
              </div>
            )}
            {data.leave_days.paid_parental_leave && (
              <div className="col-6 mb-3">
                <Container>
                  <div className="d-flex justify-content-between">
                    <Typography className="heading_semibold__20 color_black mb-4">Paid parental leave</Typography>
                    {isPtoEditable && (
                      <Button
                        data-testid="TimeOffEmployee-B0DC74"
                        size="small"
                        priority="secondary"
                        onClick={parentalOpen.setTrue}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                  <List
                    textPosition="horizontal"
                    hideLastBorder
                    lists={getPaidParental(data.leave_days.paid_parental_leave).map((v) => ({
                      ...v,
                      value: v.value?.join(', '),
                    }))}
                  />
                </Container>
              </div>
            )}
          </div>
        )}
        {employee?.contract_type !== 'contractor' && (
          <div className="col-6">
            <Container>
              <div className="d-flex flex-column">
                {isCarryOverAllowedByCountry ? (
                  <InfoMessage
                    type="success"
                    message="You can configure the carryover settings below. By default, these settings inherit your company’s time-off policy, but you can override them for individual employees."
                    fullWidth
                  />
                ) : (
                  <InfoMessage message={`Carryover is not supported in ${employee.working_country.name}`} fullWidth />
                )}
                <TimeOffCarryOverForm
                  employee={employee}
                  refetchEmployee={refetchEmployee}
                  isCarryOverAllowedByCountry={isCarryOverAllowedByCountry}
                />
              </div>
            </Container>
          </div>
        )}
      </div>

      {parentalOpen.value && data && (
        <PaidParentalModal
          title="Paid parental leave"
          paidParentalLeave={data.leave_days.paid_parental_leave}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={parentalOpen.setFalse}
          onSubmit={updateContractsPaidLeaves.mutate}
        />
      )}
      {bereavementOpen.value && data && (
        <PaidBereavementModal
          title="Paid bereavement leave"
          paidBereavementLeave={data.leave_days.paid_bereavement_leave}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={bereavementOpen.setFalse}
          onSubmit={updateContractsPaidLeaves.mutate}
        />
      )}
      {timeOffOpen.value && data && (
        <PaidTimeOffModal
          title="Paid time - off days"
          loading={updateContractsPaidLeaves.isLoading}
          paidLeave={data.leave_days.paid_leave}
          onClose={timeOffOpen.setFalse}
          onSubmit={updateContractsPaidLeaves.mutate}
        />
      )}
      {sickOpen.value && data && (
        <PaidSickLeaveModal
          title="Paid sick leave"
          paidSickLeave={data.leave_days.paid_sick_leave}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={sickOpen.setFalse}
          onSubmit={updateContractsPaidLeaves.mutate}
        />
      )}
    </>
  )
}
