import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import styled from 'styled-components'

import { ReactComponent as ContractCreateIcon } from '../../../../../assets/icons/contract-create-icon.svg'
import { ReactComponent as ContractUploadIcon } from '../../../../../assets/icons/contract-upload-icon.svg'

const ContractItem = styled.div`
  background-color: white;
  padding: 20px 20px 16px 20px;
  width: 560px;
  min-height: 108px;
  cursor: pointer;
  border-radius: 8px;
  margin: 16px 0;
  z-index: 2;
`
export default ({
  onNext,
  contractType,
  setContractType,
  withCancelButton,
  cancelButtonLabel,
  onCancel,
  isCancelling,
}) => {
  return (
    <div className="d-flex flex-column h-100 employees-page__form">
      <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <ContractItem
          style={{
            border: `4px solid ${contractType === 'create' ? '#40b84c' : '#dfdfdf'}`,
          }}
          onClick={() => !isCancelling && setContractType('create')}
        >
          <ContractCreateIcon />
          <Typography className="heading_semi__16 mt-3">Create contract</Typography>
          <Typography className="color_grey mt-2">
            Create, sign and send a contract from your account using Remofirst’s contract creation tool.
          </Typography>
        </ContractItem>
        <ContractItem
          style={{
            border: `4px solid ${contractType === 'upload' ? '#40b84c' : '#dfdfdf'}`,
          }}
          onClick={() => !isCancelling && setContractType('upload')}
        >
          <ContractUploadIcon />
          <Typography className="heading_semi__16 mt-3">Upload pre-signed contract</Typography>
          <Typography className="color_grey mt-2">
            Please upload contract that was already signed with a contractor.
          </Typography>
        </ContractItem>
        <div className="d-flex pb-5 align-self-center gap-3 mt-5">
          {withCancelButton && (
            <Button className="align-self-end" priority="secondary" onClick={onCancel} loading={!!isCancelling}>
              {cancelButtonLabel ?? 'Cancel'}
            </Button>
          )}
          <Button
            data-testid="contract-form-select-EA3276"
            className="align-self-end"
            onClick={onNext}
            disabled={!!isCancelling}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}
