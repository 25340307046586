import Step from '@atoms/Stepper/Step'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import Typography from '@atoms/Typography/Typography'
import { KOMPASSIFY_SURVEYS } from '@core/constants'
import { runKompassifySurvey } from '@core/utils'
import ContractorSuccessModal from '@pages/employees/contractor-success-modal'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useBoolean } from 'usehooks-ts'

import { ContractForm } from './contract-form/contract-form'
import BasicInformation from './form/BasicInformationForm'
import CompensationForm from './form/CompensationForm'
import JobDetailsForm from './form/JobDetailsForm'
import { MilestonesForm } from './form/Milestones'

const step1 = 'Personal details'
const step2 = 'Job details'
const step3 = 'Compensation'
const step4 = 'Milestones'
const step5 = 'Contract'

export default ({
  onMassImport,
  loading,
  onClose,
  isContinuedContract,
  onSave,
  addedNewEmployee,
  onCloseContractModal,
}) => {
  const [activeStep, setStep] = useState(1)
  const updateContractOpen = useBoolean(false)
  const [hasMilestones, setHasMilestones] = useState(false)
  const isLastStep = (hasMilestones && activeStep === 5) || (!hasMilestones && activeStep === 4)

  const [payload, setPayload] = useState({
    contract_type: 'contractor',
  })

  const handleBack = () => {
    setStep((prev) => prev - 1)
  }

  const handleNext = (formValues) => {
    if (formValues)
      setPayload((prev) => ({
        ...prev,
        ...formValues,
      }))
    setStep((prev) => Number(prev) + 1)
  }

  const handleFinish = (formValues) => {
    setPayload((prev) => {
      const body = { ...prev, ...formValues }

      onSave(body)

      return body
    })
  }

  return (
    <Modal show onClose={onClose} fullscreen>
      <Modal.Body className="p-0">
        <Stepper
          activeStep={activeStep}
          title="Add a contractor"
          description={
            <>
              Please fill in the details of the contractor you are inviting. <br />
              <Typography className="mt-4">
                Have a large number of contractors to add? Try{' '}
                <span role="button" aria-hidden style={{ color: '#40b84c' }} onClick={onMassImport}>
                  Mass Import
                </span>
              </Typography>
            </>
          }
        >
          <Step step="1" title={step1} setStep={setStep} disabled={isLastStep}>
            <StepContent title={step1} onClose={onClose}>
              {!isLastStep && <BasicInformation onNext={handleNext} />}
            </StepContent>
          </Step>

          <Step step="2" title={step2} setStep={setStep} disabled={isLastStep}>
            <StepContent title={step2} onClose={onClose} onBack={handleBack}>
              {!isLastStep && <JobDetailsForm onNext={handleNext} />}
            </StepContent>
          </Step>

          <Step step="3" title={step3} setStep={setStep} disabled={isLastStep}>
            <StepContent title={step3} onClose={onClose} onBack={handleBack}>
              {!isLastStep && (
                <CompensationForm
                  loading={loading}
                  initValue={payload}
                  onFinish={hasMilestones ? handleNext : handleFinish}
                  workingCountryId={payload?.working_country}
                  setHasMilestones={setHasMilestones}
                  hasMilestones={hasMilestones}
                />
              )}
            </StepContent>
          </Step>

          {hasMilestones && (
            <Step step="4" title={step4} setStep={setStep} disabled={isLastStep}>
              <StepContent title={step4} onClose={onClose} onBack={handleBack}>
                {!isLastStep && <MilestonesForm onFinish={handleFinish} initValue={payload} loading={loading} />}
              </StepContent>
            </Step>
          )}

          <Step step={hasMilestones ? '5' : '4'} title={step5} setStep={setStep}>
            <StepContent title={step5} onClose={onClose}>
              <ContractForm updateContractOpen={updateContractOpen} contractor={addedNewEmployee} onClose={onClose} />
            </StepContent>
          </Step>
        </Stepper>
      </Modal.Body>

      {isContinuedContract && (
        <ContractorSuccessModal
          onClose={() => {
            runKompassifySurvey(KOMPASSIFY_SURVEYS.ContractorAdded)
            onCloseContractModal()
            onClose()
          }}
          onSave={() => {
            runKompassifySurvey(KOMPASSIFY_SURVEYS.ContractorAdded)
            handleNext()
            onCloseContractModal()
          }}
        />
      )}
    </Modal>
  )
}
