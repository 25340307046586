import './Table.scss'

import Button from '@atoms/Button/Button'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { useClientPagination } from '@core/hooks/useClientPagination'
import { formatMinutesToHours } from '@core/utils'

export const TIMESHEET_FIELDS = [
  {
    title: 'Email',
    key: 'email',
    type: 'string',
    maxWidth: 400,
    minWidth: 300,
  },
  {
    title: 'Total hours',
    key: 'aggregated_minutes',
    type: 'string',
    maxWidth: 300,
    minWidth: 200,
  },
]
const LIMIT = 5
export const ValidateTimesheet = ({ payload, onNext, onReUpload }) => {
  const { timesheetFields = [] } = payload
  const timesheets = timesheetFields.map((field) => ({
    ...field,
    aggregated_minutes:
      typeof field.aggregated_minutes === 'number'
        ? formatMinutesToHours(field.aggregated_minutes)
        : field.aggregated_minutes,
  }))
  const { currentPage, currentData, goToPage, totalPages } = useClientPagination(timesheets, LIMIT, 1)
  const handleNextClick = () => {
    onNext({
      timesheetFields,
    })
  }

  return (
    <div style={{ width: 850 }}>
      <div className="mb-5">
        <Typography classname="light_regular_normal__16">
          Please confirm your timesheet and the Email and total hours columns for input are correct.
        </Typography>
      </div>
      <CustomTable
        fields={TIMESHEET_FIELDS}
        data={currentData}
        onPage={goToPage}
        page={currentPage}
        total={totalPages}
        pageSize={LIMIT}
      />
      <div className="d-flex pb-5 justify-content-center gap-4 mt-5">
        <Button onClick={onReUpload} priority="secondary" className="align-self-end">
          Re-upload
        </Button>
        <Button onClick={handleNextClick} type="submit" className="align-self-end">
          Next
        </Button>
      </div>
    </div>
  )
}
