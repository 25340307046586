/* eslint-disable react/no-unstable-nested-components */

import NotFound from '@atoms/NotFound/NotFound'
import CollapseTable from '@atoms/Table/CollapseTable'
import ContractorPaymentsTable from '@atoms/Table/ContractorPaymentsTable/ContractorPaymentsTable'
import SelectTable from '@atoms/Table/SelectTable'
import MainTable from '@atoms/Table/Table'

import { useCustomTableFields } from './useCustomTableFields'

export default function CustomTable({
  fields,
  data,
  notFoundTitle,
  onRowClick,
  onChangeSelect,
  customSelectedIds,
  type,
  onCollapseClick,
  id,
  pageSize,
  loading,
  fetching,
  page,
  total,
  collapseId,
  onPage,
  allPayments,
  activePayments,
  selectedIds,
  isSuccess,
  sorting,
  setSorting,
}) {
  const columns = useCustomTableFields(fields, collapseId)
  // isSuccess is a better check for refetches, keep !loading for compatibility
  const notFoundCondition = isSuccess || !loading
  if (notFoundCondition && (!data || !data.length)) {
    return <NotFound title={notFoundTitle ?? "It's quiet in here ..."} />
  }
  const renderTable = () => {
    switch (type) {
      case 'select':
        return (
          <SelectTable
            loading={loading}
            columns={columns}
            data={data}
            page={page}
            customSelectedIds={customSelectedIds}
            total={total}
            onPage={onPage}
            onChangeSelect={onChangeSelect}
          />
        )
      case 'contractor-payments':
        return (
          <ContractorPaymentsTable
            loading={loading}
            columns={columns}
            data={data}
            page={page}
            pageSize={pageSize}
            total={total}
            onPage={onPage}
            setSelectedIds={onChangeSelect}
            selectedIds={selectedIds}
            activePayments={activePayments}
            allPayments={allPayments}
          />
        )
      case 'main':
        return (
          <MainTable
            loading={loading}
            page={page}
            pageSize={pageSize}
            total={total}
            columns={columns}
            data={data}
            onRowClick={onRowClick}
            onPage={onPage}
          />
        )
      case 'collapse':
        return (
          <CollapseTable
            columns={columns}
            data={data}
            id={id}
            collapseId={collapseId}
            onCollapseClick={onCollapseClick}
            page={page}
            pageSize={pageSize}
            total={total}
            onPage={onPage}
          />
        )
      default:
        return (
          <MainTable
            loading={loading}
            fetching={fetching}
            page={page}
            pageSize={pageSize}
            total={total}
            columns={columns}
            data={data}
            onRowClick={onRowClick}
            onPage={onPage}
            sorting={sorting}
            setSorting={setSorting}
          />
        )
    }
  }

  return renderTable()
}
