import './time-off.styles.scss'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { TIME_OFF_STATUSES, USER_GROUPS } from '@core/constants'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useNotification'
import { useRouteQuery } from '@core/hooks/useRouteQuery'
import { coin, searchIcon } from '@core/icons/icons'
import { userHasOneOfGroupsOrSuper } from '@core/utils'
import { TimeOffRequests } from '@features/time-off/time-off-requests/time-off-requests.component'
import ListHolidaysModal from '@pages/time-off/ListHolidaysModal'
import { TimeOffModal } from '@pages/time-off/time-off-modal/time-off-modal'
import moment from 'moment'
import React, { useState } from 'react'
import { HiDownload } from 'react-icons/hi'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useBoolean, useDebounceValue } from 'usehooks-ts'

import { TimeOffBalances } from '../../features/time-off/time-off-balances/time-off-balances.component'
import {
  createTimeOff,
  downloadTimeOffXLS,
  fetchTimeOff,
  updateTimeOff,
  updateTimeOffAttachment,
  updateTimeOffStatus,
} from '../../services/absences.service'

export const TimeOffPage = () => {
  const navigate = useNavigate()
  const { userGroups } = useApp()
  const { successAlert } = useToast()
  const routeQuery = useRouteQuery()
  const queryClient = useQueryClient()

  const [tab, setTab] = useState(0)
  const [activeTimeOff, setActiveTimeOff] = useState(null)
  const listHolidays = useBoolean(false)
  const editTimOffModalState = useBoolean(false)
  const [createdTimeOffId, setCreatedTimeOffId] = useState(null)
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useDebounceValue(search)

  const downloadTimeOffRequest = useMutation({
    mutationFn: downloadTimeOffXLS,
    onSuccess: (response) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `time_off_${moment(new Date()).format('DD_MM_YYYY')}.xls`)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    },
  })
  const onTabChange = (id) => {
    setTab(id)
  }

  const newTimOffModalState = useBoolean(Boolean(routeQuery.get('openAddModal')))

  const handleModalOpen = () => {
    newTimOffModalState.setTrue()
  }

  const handleModalClose = () => {
    routeQuery.delete('openAddModal')
    navigate({
      search: routeQuery.toString(),
    })
    newTimOffModalState.setFalse()
    editTimOffModalState.setFalse()
  }

  const createTimeOffMutation = useMutation({
    mutationFn: (payload) => createTimeOff(payload),
    onSuccess: (response) => {
      if (response.data) {
        setCreatedTimeOffId(response.data.id)
      }
      handleModalClose()
      queryClient.refetchQueries(fetchTimeOff.key)
      successAlert('Successfully created!')
    },
  })

  const updateTimeOffStatusMutation = useMutation({
    mutationFn: (payload) => updateTimeOffStatus(payload),
    onSettled: () => {
      queryClient.refetchQueries(fetchTimeOff.key)
      handleModalClose()
    },
  })

  const editTimeOffMutation = useMutation({
    mutationFn: (payload) => updateTimeOff(payload),
    onSuccess: () => {
      handleModalClose()
      queryClient.refetchQueries(fetchTimeOff.key)
      successAlert('Successfully updated!')
    },
  })

  const updateTimeOffAttachmentMutation = useMutation({
    mutationFn: (payload) => updateTimeOffAttachment(payload),
    onSuccess: (response) => {
      if (response.data) {
        setActiveTimeOff(response.data)
      }
      queryClient.refetchQueries(fetchTimeOff.key)
      successAlert('Successfully updated attachment!')
    },
  })

  const handleTimeOffAttachmentDelete = () => {
    const data = {
      id: activeTimeOff.id,
      data: {
        contract: activeTimeOff.contract.id,
        attached_document: null,
      },
    }

    updateTimeOffAttachmentMutation.mutate(data)
  }

  const handleTimeOffAttachmentChange = (file_uuid) => {
    const data = {
      id: activeTimeOff.id,
      data: {
        contract: activeTimeOff.contract.id,
        attached_document: file_uuid,
      },
    }

    updateTimeOffAttachmentMutation.mutate(data)
  }

  const handleTimeOffEdit = (body) => {
    const data = {
      id: activeTimeOff.id,
      data: {
        ...body,
      },
    }
    editTimeOffMutation.mutate(data)
  }

  const handleTimeOffApprove = () => {
    updateTimeOffStatusMutation.mutate(
      {
        id: activeTimeOff.id,
        data: {
          event_side: 'COMPANY',
          status: 'APPROVED',
        },
      },
      {
        onSuccess: () => {
          successAlert('The request has been approved!')
        },
      }
    )
  }

  const handleTimeOffReject = (rejectReason) => {
    updateTimeOffStatusMutation.mutate(
      {
        id: activeTimeOff.id,
        data: {
          event_side: 'COMPANY',
          status: 'REJECTED',
          reject_reason: rejectReason,
        },
      },
      {
        onSuccess: () => {
          successAlert('The request has been rejected!')
        },
      }
    )
  }

  const handleTimeOffCancel = () => {
    updateTimeOffStatusMutation.mutate(
      {
        id: activeTimeOff.id,
        data: {
          event_side: 'COMPANY',
          status: 'CANCELED',
        },
      },
      {
        onSuccess: () => {
          successAlert('The request has been cancelled!')
        },
      }
    )
  }

  const handleRowClick = (data) => {
    editTimOffModalState.setTrue()
    setActiveTimeOff(data)
  }

  const isDisabled =
    activeTimeOff?.status === TIME_OFF_STATUSES.CANCELED ||
    activeTimeOff?.status === TIME_OFF_STATUSES.REJECTED ||
    activeTimeOff?.status === TIME_OFF_STATUSES.APPROVED ||
    activeTimeOff?.status === TIME_OFF_STATUSES.TAKEN

  const isReview = (timeOff) => {
    if (!timeOff) return false
    if (!userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST, USER_GROUPS.PTO_MANAGER])) return false
    if (timeOff.request_creator_role !== 'COMPANY' && timeOff.status === 'PENDING') return true
    return false
  }

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
  }

  return (
    <div className="employees-time-page">
      <div className="mb-4">
        <PageTitle>Time off</PageTitle>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div>
          <Input
            data-testid="time-off.component-search"
            onChange={handleSearchChange}
            placeholder="Search"
            type="text"
            name="search"
            value={search}
            endIcon={<Icon icon={searchIcon} />}
            styleClass="time-off__search"
          />
        </div>
        <div className="d-flex align-items-center">
          <Button
            className="ml-2"
            priority="secondary"
            size="small"
            loading={downloadTimeOffRequest.isLoading}
            onClick={downloadTimeOffRequest.mutate}
            hidden={!userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST, USER_GROUPS.PTO_MANAGER])}
          >
            <HiDownload className="mr-1" />
            Download report
          </Button>
          <Button
            className="ml-2"
            priority="secondary"
            size="small"
            onClick={handleModalOpen}
            hidden={!userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST, USER_GROUPS.PTO_MANAGER])}
          >
            Add time off
          </Button>
        </div>
      </div>
      <NotificationBlock
        render={
          <Typography className="text_regular__14 ml-2">
            Manage your team members time off requests. Head to the following link to check the{' '}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span className="link-public-holidays" onClick={listHolidays.setTrue}>
              Public Holidays
            </span>{' '}
            by country. Time off requests are available for full-time employees only.
          </Typography>
        }
        cards={[
          {
            title: 'Create a time off request',
            description: 'Select Add Time off button, fill in the details and wait for employee to approve or reject',
            icon: coin,
          },
          {
            title: 'Review time off requests',
            description: 'Approve or reject time off requests created by others',
            icon: coin,
          },
        ]}
      />

      <Tabs className="mt-4" onTabChange={onTabChange} selectedTab={tab}>
        <Tab tabId={0} title="Requests">
          <TimeOffRequests search={debouncedSearch} onRowClick={handleRowClick} onAdd={handleModalOpen} />
        </Tab>
        <Tab tabId={1} title="Balances">
          <TimeOffBalances search={debouncedSearch} />
        </Tab>
      </Tabs>

      {newTimOffModalState.value && (
        <TimeOffModal
          title="Add time off"
          onClose={handleModalClose}
          loading={createTimeOffMutation.isLoading}
          onSubmit={(data) => createTimeOffMutation.mutate(data)}
        />
      )}
      {editTimOffModalState.value && (
        <TimeOffModal
          title="Review time off request"
          onClose={handleModalClose}
          timeOff={activeTimeOff}
          isEdit={activeTimeOff && !isReview(activeTimeOff)}
          isReview={isReview(activeTimeOff)}
          isDisabled={isDisabled}
          onAttachmentChange={handleTimeOffAttachmentChange}
          onAttachmentDelete={handleTimeOffAttachmentDelete}
          isAttachmentLoading={updateTimeOffAttachmentMutation.isLoading}
          onSubmit={handleTimeOffEdit}
          onCancel={handleTimeOffCancel}
          onApprove={handleTimeOffApprove}
          onReject={handleTimeOffReject}
          isLoadingApprove={updateTimeOffStatusMutation.isLoading}
          isLoadingReject={updateTimeOffStatusMutation.isLoading}
          isLoadingCancel={updateTimeOffStatusMutation.isLoading}
        />
      )}

      {listHolidays.value && <ListHolidaysModal onClose={listHolidays.setFalse} />}
    </div>
  )
}
