import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import List from '@atoms/List/List'
import Typography from '@atoms/Typography/Typography'
import { USER_GROUPS } from '@core/constants'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useNotification'
import { add, check } from '@core/icons/icons'
import { userHasGroup } from '@core/utils'
import { assignGroups, fetchAllGroups } from '@services/iam.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { CloseButton, Container, Modal, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import { BsQuestion } from 'react-icons/bs'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'

export const mapGroupToList = (group) => ({
  label: group.name,
  value: group.group_description.short_description,
})

const extractGroupIds = (groups) => groups.map((group) => group.id)

export const AssignGroupsModal = ({ selectedManager, onHide, refetchManagers }) => {
  const { successAlert, failedAlert } = useToast()
  const { refetchUserGroups } = useApp()

  const [selectedGroups, setSelectedGroups] = useState(selectedManager.groups || [])

  const superSelected = userHasGroup(selectedGroups, USER_GROUPS.SUPERUSER)

  const allGroupsQuery = useQuery({
    queryKey: fetchAllGroups.key,
    queryFn: fetchAllGroups.fetch,
  })

  const { mutate: mutateAssignGroups, isLoading: isLoadingAssignGroups } = useMutation(
    'assignUserGroups',
    assignGroups,
    {
      onSuccess: () => {
        successAlert('Groups assigned successfully')
        onHide()
        refetchManagers()
        refetchUserGroups()
      },
      onError: (error) => {
        const { message } = error
        failedAlert(message)
      },
    }
  )

  const selectGroup = (group) => {
    setSelectedGroups((prevselectedGroups) =>
      group.id === USER_GROUPS.SUPERUSER ? [group] : [...prevselectedGroups, group]
    )
  }

  const deselectGroup = (deselectedGroup) => {
    setSelectedGroups((prevselectedGroups) => prevselectedGroups.filter((group) => group.id !== deselectedGroup.id))
  }

  const handleSubmit = () => {
    mutateAssignGroups({
      user: selectedManager.uid,
      groups: extractGroupIds(selectedGroups),
      delete: false,
    })
  }

  const generateGroupsList = (groups) =>
    groups.map((group) => {
      const popover = (
        <Popover id={`user-group-${group.id}-tooltip`} style={{ maxWidth: '500px' }}>
          <Popover.Header as="h3">{group.name}</Popover.Header>
          <Popover.Body>
            {group.group_description.long_description.map((description) => (
              <div>&bull; {description}</div>
            ))}
          </Popover.Body>
        </Popover>
      )

      let action = null

      if (!superSelected)
        action =
          selectedGroups.find((selectedGroup) => selectedGroup.id === group.id) !== undefined ? (
            <Button
              data-testid="assign-groups-modal-3039EC"
              priority="outlined"
              size="small"
              onClick={() => deselectGroup(group)}
            >
              <Icon icon={check} fill="#40b84c" />
            </Button>
          ) : (
            <Button
              data-testid="assign-groups-modal-3E7292"
              priority="outlined"
              size="small"
              onClick={() => selectGroup(group)}
            >
              <Icon icon={add} fill="black" />
            </Button>
          )

      return {
        ...mapGroupToList(group),
        action,
        tooltip: (
          <OverlayTrigger placement="right" overlay={popover}>
            <TooltipButton type="button">
              <BsQuestion />
            </TooltipButton>
          </OverlayTrigger>
        ),
      }
    })

  return (
    <Modal centered show onHide={onHide}>
      <Modal.Header closeButton className="mb-0">
        <Modal.Title>Assign groups to {`${selectedManager.first_name} ${selectedManager.last_name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-4">
          <Container className="p-2">
            <Row className="gap-2 over">
              {selectedGroups.map((group) => (
                <SelectedGroup key={group.id}>
                  {mapGroupToList(group).label} <CloseButton onClick={() => deselectGroup(group)} />
                </SelectedGroup>
              ))}
            </Row>
          </Container>
          {allGroupsQuery.isFetching ? (
            <div className="align-self-center">
              <DotWave size={28} speed={1} color="black" />
            </div>
          ) : (
            <List lists={generateGroupsList(allGroupsQuery.data.results)} />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="assign-groups-modal-55DD47"
          type="button"
          priority="secondary"
          size="small"
          onClick={onHide}
        >
          Cancel
        </Button>
        <Button
          data-testid="assign-groups-modal-CC4721"
          type="submit"
          priority="primary"
          size="small"
          onClick={() => handleSubmit()}
          loading={isLoadingAssignGroups}
          disabled={isLoadingAssignGroups || !selectedGroups.length}
        >
          <Typography>Save</Typography>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const SelectedGroup = styled('div')`
  background-color: #f5f5f5;
  width: fit-content;
  border-radius: 8px;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  gap: 4px;
`

const TooltipButton = styled('button')`
  padding: 0;
  border: none;
  background: none;
  color: #707070;
`
