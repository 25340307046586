/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import Typography from '@atoms/Typography/Typography'
import { TIMESHEET_STATE } from '@core/constants'
import { keyboardArrowLeft, miniCalendarIcon } from '@core/icons/icons'
import { formatMinutesToHours } from '@core/utils'
import { getTimesheetDetails, setTimesheetReviewAction } from '@services/timesheets.service'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import { ApproveTimesheetModal } from './ApproveTimesheetModal'
import { TASK_NAME_ON_TIMESHEET, TIMESHEET_TABLE_FIELDS } from './mock'
import { RejectionReason } from './rejection-reason/RejectionReason'
import { RejectTimesheetModal } from './RejectTimesheetModal'
import { TimesheetsTable } from './timesheets-table/TimesheetsTable'

export const TimesheetDetailsPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const showApproveTimesheetModal = useBoolean(false)
  const showRejectTimesheetModal = useBoolean(false)

  const { data, isFetching, isLoading, refetch } = useQuery([getTimesheetDetails.key], {
    queryFn: () => getTimesheetDetails.fetch(params.id),
    enabled: Boolean(params.id),
    select: (res) => {
      const timesheet_daily_entries = res.timesheet_daily_entries
        .map((daily_entry) => ({
          ...daily_entry,
          timesheet_tasks: daily_entry.timesheet_tasks.map((task) => ({
            ...task,
            task_name: TASK_NAME_ON_TIMESHEET[task.task_name],
          })),
        }))
        .flat()
      return {
        ...res,
        timesheet_daily_entries,
      }
    },
  })

  const timesheetActionMutation = useMutation({
    mutationFn: setTimesheetReviewAction,
    onSuccess: () => {
      showRejectTimesheetModal.setFalse()
      showApproveTimesheetModal.setFalse()
      toast.success('Timesheet reviewed succesfully')
      navigate('/pages/timesheets')
      refetch()
    },
  })

  const displayPeriod = () => {
    return `${moment(data.start_period).format('D MMM YYYY')} - ${moment(data.end_period).format('D MMM YYYY')}`
  }

  const handleTimesheetReject = (payload) => {
    timesheetActionMutation.mutate({
      id: params.id,
      body: {
        action: 'timesheet_reject',
        reject_reason: payload.rejection_reason,
      },
    })
  }

  const handleTimesheetApprove = () => {
    timesheetActionMutation.mutate({
      id: params.id,
      body: {
        action: 'timesheet_approve',
      },
    })
  }

  const displayHeader = () => {
    switch (data.state) {
      case TIMESHEET_STATE.PENDING:
        return (
          <>
            <Typography className="heading_semibold__24 ml-3">{`Review ${data.full_name} timesheet ${displayPeriod()}`}</Typography>
            <div className="d-flex gap-2">
              <Button onClick={showRejectTimesheetModal.setTrue} priority="primary_dangerous" size="small">
                Reject
              </Button>
              <Button onClick={showApproveTimesheetModal.setTrue} size="small">
                Approve
              </Button>
            </div>
          </>
        )
      case TIMESHEET_STATE.REJECTED:
      case TIMESHEET_STATE.APPROVED:
      case TIMESHEET_STATE.DRAFT:
      case TIMESHEET_STATE.ARCHIVED:
      case TIMESHEET_STATE.PENDING_INVOICE:
      case TIMESHEET_STATE.PENDING_PAYMENT_APPROVAL:
      case TIMESHEET_STATE.PAID:
        return (
          <Typography className="heading_semibold__24 ml-3">{`View ${data.full_name} timesheet ${displayPeriod()}`}</Typography>
        )
      default:
        return ''
    }
  }

  const displayState = () => {
    switch (data.state) {
      case TIMESHEET_STATE.PENDING:
        return 'Timesheet is ready for review, please approve or reject.'
      case TIMESHEET_STATE.REJECTED:
        return 'Timesheet has been rejected.'
      case TIMESHEET_STATE.APPROVED:
        return 'Timesheet has been approved.'
      case TIMESHEET_STATE.PAID:
        return 'Timesheet has been paid.'
      case TIMESHEET_STATE.PENDING_INVOICE:
        return 'Timesheet is pending invoice.'
      case TIMESHEET_STATE.ARCHIVED:
        return 'Timesheet is archived.'
      case TIMESHEET_STATE.PENDING_PAYMENT_APPROVAL:
        return 'Timesheet is pending payment approval.'
      case TIMESHEET_STATE.DRAFT:
        return 'Draft version of timesheet.'
      default:
        return ''
    }
  }

  if (isFetching || isLoading) {
    return (
      <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: 500 }}>
        <DotWave size={40} speed={1} color="black" />
      </div>
    )
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            border: '1px solid #CCCCCC',
            boxSizing: 'border-box',
            borderRadius: '8px',
            height: 40,
            width: 40,
          }}
          onClick={() => navigate(-1)}
        >
          <Icon icon={keyboardArrowLeft} style={{ cursor: 'pointer', transform: 'rotate(180deg)' }} />
        </div>
        <div className="d-flex w-100 align-items-center justify-content-between">{displayHeader()}</div>
      </div>
      <Typography className="heading_semibold__18 mt-4">{displayState()}</Typography>
      {data.reject_reason && <RejectionReason reason={data.reject_reason} />}
      <div className="mt-4 gap-2" style={{ width: '500px' }}>
        <div className="d-flex gap-2">
          <Input
            style={{ borderRadius: 8, height: 38, margin: 0 }}
            disabled
            label="Start date"
            value={moment(data.start_period).format('D MMM YYYY')}
            endIcon={<Icon icon={miniCalendarIcon} />}
          />
          <Input
            style={{ borderRadius: 8, height: 38, margin: 0 }}
            disabled
            label="End date"
            value={moment(data.end_period).format('D MMM YYYY')}
            endIcon={<Icon icon={miniCalendarIcon} />}
          />
        </div>
        <Input
          style={{ borderRadius: 8, height: 38, margin: 0 }}
          styleClass="mt-2"
          label="Total hours"
          disabled
          value={formatMinutesToHours(data.total_minutes_spent)}
        />
      </div>
      {data.timesheet_daily_entries.map((item, index) => {
        return (
          <div key={index} className="mt-4 mb-4">
            <TimesheetsTable
              fields={TIMESHEET_TABLE_FIELDS}
              data={item.timesheet_tasks}
              day={item.entry_date}
              totalHours={formatMinutesToHours(item.total_minutes_spent)}
            />
          </div>
        )
      })}
      {showApproveTimesheetModal.value && (
        <ApproveTimesheetModal
          onClose={showApproveTimesheetModal.setFalse}
          onConfirm={handleTimesheetApprove}
          isLoading={timesheetActionMutation.isLoading}
        />
      )}
      {showRejectTimesheetModal.value && (
        <RejectTimesheetModal
          onClose={showRejectTimesheetModal.setFalse}
          onConfirm={handleTimesheetReject}
          isLoading={timesheetActionMutation.isLoading}
        />
      )}
    </>
  )
}
