import React from 'react'

export const SuccessSvg = () => (
  <svg
    data-testid="success-svg-C0CA62"
    width="206"
    height="201"
    viewBox="0 0 206 201"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11987_249621)">
      <path
        d="M0.81004 195.473C-1.33675 193.558 8.20181 193.7 12.9097 193.611C13.5876 194.569 14.0866 194.587 15.3107 194.808C15.8756 196.271 2.95683 197.388 0.81004 195.473Z"
        fill="black"
      />
      <path
        d="M74.2111 50.713C73.7746 52.3024 73.4492 57.4672 75.1636 61.7052"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M72.8223 62.744C76.8908 61.2773 84.6384 59.4824 84.6384 64.5561V65.4365C84.6384 66.0309 85.3135 66.3677 85.7805 66.0037C92.3118 60.9127 105.601 50.713 110.284 42.9923"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M90.8525 23.4943C94.8494 26.66 101.053 33.493 95.0232 36.2727C94.6697 36.4355 94.4746 36.8296 94.5755 37.2054C95.7618 41.6248 95.5742 50.7504 86.3713 56.9304C86.2342 57.0223 86.1289 57.1589 86.0789 57.3159L84.269 63.0138"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M86.8967 46.7872C86.7547 47.4154 87.1236 48.7135 89.7324 48.881"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M93.8944 30.299C93.8944 31.6753 93.4808 32.8855 92.8554 33.7311C92.2297 34.5762 91.4378 35.0099 90.6425 35.0099C89.8472 35.0099 89.0553 34.5762 88.4297 33.7311C87.8043 32.8855 87.3906 31.6753 87.3906 30.299C87.3906 28.9226 87.8043 27.7124 88.4297 26.8668C89.0553 26.0218 89.8472 25.588 90.6425 25.588C91.4378 25.588 92.2297 26.0218 92.8554 26.8668C93.4808 27.7124 93.8944 28.9226 93.8944 30.299Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M78.8057 34.4865L86.8704 31.6075" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M85.0493 28.9904C85.2546 28.118 86.0106 26.1113 87.3907 25.0646"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M91.0328 31.0841C91.6075 31.0841 92.0734 30.7326 92.0734 30.299C92.0734 29.8653 91.6075 29.5138 91.0328 29.5138C90.4581 29.5138 89.9922 29.8653 89.9922 30.299C89.9922 30.7326 90.4581 31.0841 91.0328 31.0841Z"
        fill="black"
      />
      <path
        d="M87.4942 116.928C87.0153 111.783 86.6318 100.43 88.9318 96.1754C91.2316 91.9212 93.4413 85.8357 94.9954 83.3739C100.222 80.3385 112.678 68.6931 120.69 46.3947"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M72.8223 62.4904L42.9048 97.8224" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M42.9048 97.9308C45.1228 97.6295 50.8889 97.7756 56.2116 100.774C62.8652 104.521 69.9096 111.112 87.3908 116.928"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M87.3907 116.928L81.4072 121.115" stroke="black" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M42.9081 97.8224C42.5028 99.0862 42.1006 102.034 45.5063 105.674"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M64.7575 72.174C59.916 76.2309 47.6397 84.7898 37.2654 86.57C34.8449 87.5732 30.6778 90.3911 33.375 93.6367C36.0726 96.8826 37.8707 100.835 38.4327 102.405C38.865 103.845 39.941 105.852 42.5347 101.246"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M34.32 94.9435C32.3101 96.1359 27.6232 98.4141 24.9547 97.9901C22.2863 97.5664 20.5073 98.6968 19.9513 99.315C19.652 99.8007 19.4638 100.798 21.106 100.905C22.7481 101.011 24.6982 100.949 25.4679 100.905"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M27.556 99.131C26.6559 100.146 23.5952 102.452 18.5541 103.559C17.5682 103.744 16.2393 104.804 18.0397 106.05C19.5829 107.018 23.8523 106.133 26.0128 104.804"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M25.9895 104.627C24.3361 105.465 21.3599 107.694 22.6827 109.914C23.1066 110.355 24.8956 110.39 28.6604 107.006L31.4583 104.627"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M31.5981 104.678L28.7413 107.078C27.9187 109.257 26.6635 114.548 30.0397 111.613C32.507 109.079 31.9875 106.918 33.0265 106.278C34.0653 105.638 36.4028 103.166 37.4416 102.01"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M31.7205 100.44C31.3745 101.517 31.0977 104.02 32.7587 105.412"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M63.1968 188.639C64.8331 188.203 68.1319 185.996 68.2351 180.656C68.3387 175.317 70.5177 166.131 71.594 162.205L82.1879 115.096"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M86.0901 39.1974C86.3776 39.1974 86.6104 39.0215 86.6104 38.8048C86.6104 38.5881 86.3776 38.4122 86.0901 38.4122C85.8027 38.4122 85.5698 38.5881 85.5698 38.8048C85.5698 39.0215 85.8027 39.1974 86.0901 39.1974Z"
        fill="black"
      />
      <path
        d="M87.7809 40.7677C87.9963 40.7677 88.1711 40.6505 88.1711 40.506C88.1711 40.3615 87.9963 40.2443 87.7809 40.2443C87.5654 40.2443 87.3906 40.3615 87.3906 40.506C87.3906 40.6505 87.5654 40.7677 87.7809 40.7677Z"
        fill="black"
      />
      <path
        d="M84.0087 41.8146C84.1523 41.8146 84.2688 41.5803 84.2688 41.2911C84.2688 41.0019 84.1523 40.7677 84.0087 40.7677C83.8651 40.7677 83.7485 41.0019 83.7485 41.2911C83.7485 41.5803 83.8651 41.8146 84.0087 41.8146Z"
        fill="black"
      />
      <path
        d="M45.5701 35.4469C51.8923 23.6248 61.3327 22.1384 65.2628 22.8727C73.4249 10.2986 84.6965 6.15052 91.5629 9.90984C97.0563 12.9172 92.5565 20.4962 89.6196 23.91L86.7696 19.7619C84.8003 23.5989 79.1688 22.1815 76.4046 21.3173C78.7798 26.3297 82.5716 39.2843 74.5909 51.0027C66.6102 62.7214 56.5823 62.9285 51.5294 59.5583C46.8654 56.2313 39.2476 47.2693 45.5701 35.4469Z"
        fill="black"
      />
      <path
        d="M42.3843 190.209C43.2467 191.567 46.1188 194.13 50.7092 193.519"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M49.6144 98.6075C47.9866 103.292 40.3602 112.92 33.5484 125.022C29.0069 132.612 18.1761 149.562 11.1841 156.641C10.2416 157.985 9.359 161.038 13.3691 162.496C16.4538 163.797 17.842 166.816 18.2533 170.043C18.7674 174.077 25.3224 177.069 29.6924 174.076C33.1756 171.691 43.1909 154.509 50.7575 144.8C52.6839 142.328 54.4516 140.34 55.9124 139.205"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M50.9443 144.932L42.3843 190.209C46.7353 189.041 58.6872 186.836 66.0581 186.836"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.33917 186.467C1.24828 172.639 7.8215 163.437 11.3765 161.158C16.8875 162.902 17.0364 168.715 17.9301 171.476L15.8675 177.158C14.4873 179.602 15.2924 184.382 15.8675 186.467C18.2515 196.125 14.6533 197.054 12.8875 194.467L8.33917 186.467Z"
        fill="black"
      />
      <path
        d="M46.1463 199.147C43.7578 197.244 48.4817 196.507 53.2752 196.229C54.9454 197.731 66.0375 198.244 66.5786 198.591C61.4758 200.26 48.2395 200.815 46.1463 199.147Z"
        fill="black"
      />
      <path
        d="M70.1376 198.332C52.0279 200.83 48.2695 193.624 48.6537 189.412C51.7683 188.103 60.3327 187.628 64.37 187.33C63.2165 190.066 64.6583 189.709 65.6677 190.453C66.9656 191.344 70.5702 192.435 72.5888 192.534C81.6728 192.98 74.5594 197.688 70.1376 198.332Z"
        fill="black"
      />
      <path
        d="M112.798 19.9548C113.628 19.475 115.339 19.3005 115.547 22.4411"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M111.125 28.3298C111.211 27.1086 111.125 24.3256 110.084 22.9646C108.783 21.2635 108.523 17.992 111.125 18.2537C111.95 18.3368 112.476 19.027 112.798 19.9549C113.289 21.3653 113.31 23.3247 113.206 24.535"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M109.489 31.7321C109.383 30.5543 108.939 27.8324 108.002 26.3668C106.832 24.5348 106.702 19.8239 109.173 20.3473"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M111.124 40.6305C110.474 40.6305 109.095 40.0024 108.783 37.4899C108.471 34.9774 107.352 33.3898 106.832 32.9098C106.528 32.1684 106.208 30.6329 107.352 30.4235C108.026 30.3002 108.823 30.9574 109.489 31.7321L110.604 33.3024"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M117.108 28.984C117.411 28.4171 117.706 26.9688 116.457 25.7126C114.896 24.1423 115.287 22.7028 116.197 22.3103C117.108 21.9177 118.409 22.1794 119.709 26.1052C121.01 29.5075 122.935 38.1442 120.75 45.4723"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M123.05 8.50812L125.091 4.77515" stroke="#40b84c" strokeWidth="2" strokeLinecap="round" />
      <path d="M105.401 10.2712L109.043 12.6267" stroke="#40b84c" strokeWidth="2" strokeLinecap="round" />
      <path d="M112.009 0.785156L115.572 9.02691" stroke="#40b84c" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M124.621 19.8293C124.621 17.0817 125.256 12.495 128.56 16.649C130.593 19.6346 131.355 25.5799 129.831 28.5914"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M117.887 22.3606C117.463 20.8462 117.251 18.025 119.793 18.8557L122.588 24.5674"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M122.588 24.5674L119.793 18.8557C119.581 17.1682 120.683 14.4422 123.351 16.6489L124.621 19.8293L125.892 23.0097"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M125.953 32.6481C125.259 34.1749 124.522 37.6469 127.123 39.3219"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M129.205 30.4235C129.812 28.6789 131.651 25.2154 134.148 25.3201C136.645 25.4248 134.668 28.7223 134.148 29.9L130.506 37.0973C129.725 39.0602 128.555 42.5934 131.286 46.65C134.018 50.3141 143.437 59.736 153.583 61.3063"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M151.708 116.006C152.489 118.797 155.35 125.27 160.553 128.83L151.708 116.006ZM143.644 111.425L153.92 114.435L143.644 111.425ZM153.399 119.67C154.527 115.962 155.975 106.767 152.749 99.6481C148.717 90.7497 146.245 79.3649 148.196 71.7751C149.757 65.7032 155.437 64.7958 158.082 65.1012C162.244 65.5817 169.425 70.5973 169.008 84.2067C168.488 101.218 164.586 115.744 158.993 126.998L153.399 119.67Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M149.237 125.82C149.237 119.146 149.193 114.435 148.456 112.603C158.446 116.372 170.569 115.395 175.382 114.435C175.512 114.741 176.579 117.654 179.804 126.867C183.837 138.382 180.975 176.855 179.804 181.173C178.634 185.492 175.512 185.623 170.959 185.099C167.317 184.68 162.504 174.63 160.553 169.658C151.448 152.515 149.237 133.651 149.237 125.82Z"
        fill="black"
      />
      <path
        d="M184.557 91.0205C185.848 94.2048 188.766 102.301 190.119 109.21C186.39 111.871 175.902 116.922 163.415 115.351L143.643 111.425C142.863 104.839 142.771 88.0631 147.974 73.0928C147.974 71.7407 149.341 67.9539 152.358 66.2789"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M183.447 102.396C183.391 101.556 184.932 95.4431 184.557 91.0205C184.348 88.5544 184.081 85.4776 183.745 81.7295C182.809 71.2608 179.106 63.2349 177.371 60.5303C175.637 57.826 171.534 56.2028 168.282 57.3805"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M180.95 172.525C182.071 147.981 177.098 124.791 174.341 115.744C181.294 114.8 186.771 111.776 188.78 110.771C190.798 118.428 193.752 138.086 194.967 147.09C197.995 156.949 197.35 171.127 196.649 176.983C197.098 180.549 194.22 181.965 192.724 182.227C184.762 184.85 181.557 176.852 180.95 172.525Z"
        fill="black"
      />
      <path
        d="M117.628 21.9177C119.839 25.4946 123.117 34.1661 120.62 46.5192C120.836 49.1801 124.696 57.9788 127.644 61.9606C132.196 68.111 141.692 75.0465 147.545 77.0094"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M157.986 59.2686C156.265 58.2032 143.321 59.2301 140.804 42.8489C136.495 42.8997 134.152 39.3489 137.44 37.8294C140.944 36.0112 140.682 32.6733 140.047 30.7065C139.037 28.1272 138.976 25.4192 139.193 24.8528"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M144.378 36.9607C143.763 36.968 143.255 36.2208 143.244 35.292C143.233 34.3631 143.724 33.6042 144.339 33.5971C144.955 33.5898 145.463 34.337 145.473 35.2658C145.484 36.1947 144.994 36.9536 144.378 36.9607Z"
        fill="black"
      />
      <path
        d="M147.006 31.5264C146.287 30.7536 144.428 29.6227 142.743 31.2838"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M151.888 46.5797C150.567 48.625 149.117 49.2058 146.299 47.8859"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M162.705 32.2435C163.011 32.9198 163.267 34.4551 161.844 35.1858"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M177.87 25.2989C179.546 34.5259 174.301 41.4716 170.499 46.8112C166.437 44.6039 159.781 39.3549 162.334 38.7882C165.017 38.1928 167.847 30.8299 165.242 28.5775C162.184 25.9349 159.429 29.9332 158.904 32.965L156.441 28.6812C157.137 25.6849 157.411 20.4289 152.933 23.3754C148.455 26.3218 141.886 25.5501 139.161 24.7959C133.789 20.9275 125.443 11.9044 135.034 6.75873C143.012 2.479 153.392 7.88975 159.729 12.7516C164.02 11.6121 175.585 12.7183 177.87 25.2989Z"
        fill="black"
      />
      <path
        d="M170.2 46.1931C168.261 48.5418 167.297 50.4921 167.417 54.5323"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M182.242 200.735C166.113 201.389 158.438 198.134 158.438 195.893C158.438 194.977 157.918 194.061 161.56 192.753C159.999 194.89 160.572 198.955 175.348 198.118C177.733 198.249 183.101 197.621 185.494 194.061H186.665C198.372 191.051 205.266 193.522 205.266 195.762C205.266 198.003 195.64 200.192 182.242 200.735Z"
        fill="black"
      />
      <path
        d="M185.265 194.004C193.977 194.842 195.076 187.81 194.536 184.19"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M173.724 187.592C173.724 188.159 173.594 189.398 173.074 189.817C172.423 190.34 160.716 189.031 160.977 194.92C160.847 195.749 162.147 197.014 165.269 197.406C169.66 197.959 182.246 199.571 185.265 194.004C185.44 193.68 185.584 193.331 185.691 192.957L186.862 187.984"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11987_249621">
        <rect width="205" height="201" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
