/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import './Table.scss'

import Icon from '@atoms/Icon/Icon'
import Pagination from '@atoms/Pagination/Pagination'
import { arrowDown } from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import classNames from 'classnames'
import { useEffect, useMemo } from 'react'
import { useSortBy, useTable } from 'react-table'

const DEFAULT_SORTING_TOOLTIP = 'Sort by'
export default function Table({
  page,
  total,
  columns,
  pageSize = 10,
  data,
  loading,
  fetching,
  onRowClick,
  onPage,
  sorting = [],
  setSorting,
}) {
  // Use the state and functions returned from useTable to build your UI
  const isServerSorting = !!setSorting
  const sortedColumns = useMemo(
    () =>
      columns.map((col) => ({
        ...col,
        sortDescFirst: true,
      })),
    [columns]
  )
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
    {
      columns: sortedColumns,
      data,
      manualSortBy: isServerSorting,

      initialState: {
        sortBy: sorting,
      },
    },
    useSortBy
  )
  const { sortBy } = state

  useEffect(() => {
    if (setSorting) {
      setSorting(sortBy)
    }
  }, [sortBy, setSorting])
  // Render the UI for your table
  if (loading) {
    return (
      <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: 300 }}>
        <DotWave size={40} speed={1} color="black" />
      </div>
    )
  }
  return (
    <div style={{ position: 'relative' }}>
      {fetching && (
        <div className="table-loader">
          <DotWave size={40} speed={1} color="black" />
        </div>
      )}
      <table {...getTableProps()} cellSpacing="0" className="mb-3" cellPadding="0">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const title = (() => {
                  if (!isServerSorting) return DEFAULT_SORTING_TOOLTIP
                  if (!column.canSort) return ''
                  if (!column.isSorted) return 'Click to sort descending'
                  // Sorting cycle is descending => ascending => none
                  return column.isSortedDesc ? 'Click to sort ascending' : 'Click to reset sorting'
                })()

                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title={title}
                    className="px-2"
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                    }}
                  >
                    <div className="d-flex align-items-center table_header">
                      {column.render('Header')}
                      {!column.isSorted && !column.disableSortBy && (
                        <Icon
                          fill="#878787"
                          className={isServerSorting ? 'server_sort_icon' : 'sort_icon'}
                          icon={arrowDown}
                        />
                      )}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon fill="#1677ff" icon={arrowDown} />
                        ) : (
                          <Icon fill="#1677ff" className="rotate" icon={arrowDown} />
                        )
                      ) : (
                        <span style={{ width: 20, height: 20 }} />
                      )}
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className={classNames([
                  onRowClick && 'cursor-pointer',
                  row.original.status === 'inactive' && 'status_inactive',
                ])}
                {...row.getRowProps()}
                onClick={
                  onRowClick
                    ? () => {
                        onRowClick(row.original)
                      }
                    : undefined
                }
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="px-2"
                    style={{
                      minWidth: cell.column.minWidth,
                      width: cell.column.width,
                      maxWidth: cell.column.maxWidth,
                      wordBreak: 'break-word',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      {page && Math.ceil(total / pageSize) > 1 && (
        <Pagination page={page} pageSize={pageSize} total={total} onGetPage={onPage} />
      )}
    </div>
  )
}
