import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { Modal } from 'react-bootstrap'

export const TimesheetsErrorModal = ({ error, onClose }) => {
  const { message, items } = error
  return (
    <Modal centered show onHide={onClose} animation scrollable>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">{message}</Typography>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: 300 }}>
        {items.map((item) => {
          return <Typography className="heading_semi__16 color_gray">{item}</Typography>
        })}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end mt-5">
          <Button priority="secondary" size="small" className="mr-3" onClick={onClose}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
