import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Typography from '@atoms/Typography/Typography'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Form } from './auth-modal.styles'

export const LoginForm = ({ prefillEmail, isModalOpen, isLoading, onResetPassword, onSubmit }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: { email: '', password: '' },
    mode: 'onChange',
  })

  const emailValue = watch('email')
  useEffect(() => {
    reset({ email: prefillEmail, password: '' })
  }, [prefillEmail, setValue, isModalOpen, reset])

  const handleClickResetPassword = () => {
    if (emailValue && !isLoading) onResetPassword({ email: emailValue })
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Input
          data-testid="login-form.component-12358F"
          {...register('email', {
            required: 'Email is required',
          })}
          label="Email"
          type="email"
          placeholder="example@mail.com"
        />
        {errors.email && <Typography className="text_regular__14 color_red">{errors.email.message}</Typography>}
      </div>

      <div>
        <Input
          data-testid="login-form.component-4AE1D4"
          {...register('password', {
            required: 'Password is required',
          })}
          label="Password"
          type="password"
        />
        {errors.password && <Typography className="text_regular__14 color_red">{errors.password.message}</Typography>}
      </div>

      <Typography
        role="button"
        aria-hidden
        style={{
          color: '#40b84c',
          display: emailValue ? 'block' : 'none',
        }}
        onClick={handleClickResetPassword}
      >
        Forgot password?
      </Typography>

      <Button
        data-testid="login-form.component-5850C2"
        loading={isLoading}
        type="submit"
        size="medium"
        style={{ marginTop: '32px' }}
        disabled={!isValid}
      >
        Continue
      </Button>
    </Form>
  )
}
