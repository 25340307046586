import './style.scss'

import Step from '@atoms/Stepper/Step'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import { CONTRACT_TYPE, EMPLOYEE_STATES } from '@core/constants'
import Compilance from '@pages/employeeDetailPage/libs/Compilance/Compilance'
import { ContractForm } from '@pages/employees/create-contractor/contract-form/contract-form'
import { fetchCountryOccupations, fetchCountryRegions } from '@services/countries.service'
import { DotWave } from '@uiball/loaders'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getEmployeeDetails } from 'src/services/employee.service'
import { useBoolean } from 'usehooks-ts'

import Agreements from './libs/Agreements/agreements'
import Benefit from './libs/Benefit/Benefit'
import DocumentDetailEmployee from './libs/DocumentDetailEmployee/DocumentDetailEmployee'
import EmergencyContact from './libs/EmergencyContact/EmergencyContact'
import { InvoiceDetailEmployee } from './libs/InvoiceDetailEmployee/InvoiceDetailEmployee'
import JobDetails from './libs/JobDetails/JobDetails'
import { JobDetailsFte } from './libs/JobDetailsFte/JobDetailsFte'
import { MilestonesTab } from './libs/MilestonesTab/MilestonesTab'
import { PaymentDetailsTab } from './libs/PaymentDetailsTab/PaymentDetailsTab'
import Personal from './libs/Personal/Personal'
import { ProfileHeader } from './libs/ProfileHeader/ProfileHeader'
import TimeOffEmployee from './libs/TimeOff/TimeOffEmployee'
import { TimesheetsTab } from './libs/TimesheetsTab/TimesheetsTab'
import { UpdateAgreementConfirmModal } from './UpdateAgreementModal'

export default function OnboardingEmployeeDetails() {
  const params = useParams()

  const [employee, setEmployee] = useState()
  const [occupations, setOccupations] = useState([])
  const [regions, setRegions] = useState([])
  const [activeStep, setStep] = useState(1)
  const updateContractOpen = useBoolean(false)
  const showUpdateContractModal = useBoolean(false)
  const openAgreementUpdateConfirm = useBoolean(false)

  const { isLoading: detailEmployeeLoading, refetch } = useQuery('detailEmployee', {
    queryFn: () => getEmployeeDetails(params.id),
    enabled: !!params.id,
    onSuccess: (v) => {
      setEmployee(v)
    },
  })

  const occupationsData = useQuery(
    'getCountryOccupations',
    () => fetchCountryOccupations(employee.working_country.id),
    {
      enabled: !!employee?.working_country?.id,
      onSuccess: (data) => {
        setOccupations(data.results)
      },
    }
  )

  const regionsData = useQuery('getCountryRegions', () => fetchCountryRegions(employee.working_country.id), {
    enabled: !!employee?.working_country?.id,
    onSuccess: (data) => {
      setRegions(data.results)
    },
  })

  const handleUpdateContractClose = () => {
    showUpdateContractModal.setFalse()
    openAgreementUpdateConfirm.setFalse()
    updateContractOpen.setFalse()
  }

  return (
    <div className="employee-detail-page h-100">
      {detailEmployeeLoading ? (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <DotWave size={48} speed={1} color="black" />
        </div>
      ) : (
        <>
          <ProfileHeader employee={employee} refetch={refetch} />
          <Tabs selectedTab={+params.tabId ?? 1}>
            <Tab tabId={0} title="Personal">
              {employee && (
                <Personal
                  employee={employee}
                  refetch={refetch}
                  setEmployee={setEmployee}
                  openAgreementUpdateConfirm={openAgreementUpdateConfirm}
                />
              )}
            </Tab>
            <Tab tabId={1} title="Job details">
              {employee?.contract_type === CONTRACT_TYPE.FULL_TIME_EMPLOYEE && (
                <JobDetailsFte
                  employee={employee}
                  setEmployee={setEmployee}
                  occupations={occupations}
                  regions={regions}
                />
              )}
              {employee?.contract_type === CONTRACT_TYPE.CONTRACTOR && (
                <JobDetails
                  employee={employee}
                  setEmployee={setEmployee}
                  occupations={occupations}
                  openAgreementUpdateConfirm={openAgreementUpdateConfirm}
                />
              )}
            </Tab>
            <Tab tabId={2} title="Payment Details" hidden={employee && employee.contract_type === 'full_time_employee'}>
              {employee && <PaymentDetailsTab employee={employee} setEmployee={setEmployee} refetch={refetch} />}
            </Tab>
            <Tab
              tabId={3}
              title="Timesheets"
              hidden={
                employee &&
                (employee.contract_type === 'full_time_employee' ||
                  employee.compensation?.contractor_wage_type !== 'hourly')
              }
            >
              {employee && <TimesheetsTab />}
            </Tab>
            <Tab
              tabId={4}
              title="Milestones"
              hidden={
                employee?.contract_type === 'full_time_employee' ||
                employee?.compensation.contractor_wage_type !== 'milestone'
              }
            >
              {employee && <MilestonesTab employee={employee} />}
            </Tab>
            <Tab tabId={5} title="Benefits" hidden={employee && employee.contract_type === 'contractor'}>
              {employee && <Benefit employee={employee} setEmployee={setEmployee} refetch={refetch} />}
            </Tab>
            <Tab
              tabId={6}
              title="Emergency contact"
              hidden={
                employee && (employee.contract_type === 'contractor' || employee.state !== EMPLOYEE_STATES.ACTIVE)
              }
            >
              {employee && <EmergencyContact employee={employee} />}
            </Tab>
            <Tab tabId={7} title="Documents">
              {employee && <DocumentDetailEmployee id={employee.id} employee={employee} />}
            </Tab>
            <Tab tabId={8} hidden={employee && employee.contract_type === 'full_time_employee'} title="Invoices">
              {employee && <InvoiceDetailEmployee id={employee.id} />}
            </Tab>
            <Tab
              tabId={9}
              title="Time-off"
              hidden={employee && employee.compensation.contractor_wage_type !== 'monthly'}
            >
              {employee && <TimeOffEmployee employee={employee} refetchEmployee={refetch} />}
            </Tab>
            <Tab tabId={10} title="Agreements">
              {employee && <Agreements employee={employee} />}
            </Tab>
            <Tab
              tabId={11}
              title="Onboarding checklist"
              hidden={employee && employee.contract_type === 'full_time_employee'}
            >
              {employee && <Compilance id={employee.id} />}
            </Tab>
          </Tabs>
        </>
      )}
      {showUpdateContractModal.value ? (
        <Modal show onClose={handleUpdateContractClose} fullscreen>
          <Modal.Body className="p-0">
            <Stepper
              activeStep={activeStep}
              title="Updating contract"
              description={
                <>
                  The current contract will be deactivated <br /> and replaced by the updated version.
                </>
              }
              sidebarStyle={{ width: 400 }}
            >
              <Step step="1" title="Update contract" setStep={setStep}>
                <StepContent title="Update contract" onClose={handleUpdateContractClose}>
                  <ContractForm
                    updateContractOpen={updateContractOpen}
                    contractor={employee}
                    onClose={handleUpdateContractClose}
                  />
                </StepContent>
              </Step>
            </Stepper>
          </Modal.Body>
        </Modal>
      ) : null}
      <UpdateAgreementConfirmModal
        show={openAgreementUpdateConfirm.value}
        onHide={openAgreementUpdateConfirm.setFalse}
        onConfirm={showUpdateContractModal.setTrue}
      />
    </div>
  )
}
