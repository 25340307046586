import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import usePagination from '@core/hooks/usePagination'
import { getInvoicesByContractId } from '@store/invoices'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'

import { getCommonInvoiceFields } from '../../../invoices/sub-invoices/SubInvoicesPage'

const fieldsToSort = ['date', 'amount', 'status']

export function InvoiceDetailEmployee({ id }) {
  const [sorting, setSorting] = useState([])

  const { page, limit, setPage } = usePagination({ page: 1, limit: 10 })

  const { data, isLoading, isFetching, isSuccess } = useQuery(['invoices', id, page, sorting[0]], {
    queryFn: () => getInvoicesByContractId(id, page, limit, sorting[0]),
    keepPreviousData: true,
  })
  const fields = [
    {
      title: 'ID',
      key: 'id',
      maxWidth: 100,
      minWidth: 80,
      width: 80,
    },
    {
      title: 'Issue date',
      key: 'date',
      type: 'Date',
      maxWidth: 170,
      minWidth: 170,
      width: 170,
    },
    ...getCommonInvoiceFields(isLoading),
  ].map((field) => ({ ...field, disableSortBy: !fieldsToSort.includes(field.key) }))
  const getInvoicesTable = useMemo(
    () =>
      (data?.data &&
        data.data.results &&
        data.data.results
          // .filter((invoice) => invoice.file)
          .map((invoice) => {
            return {
              ...invoice,
              date: moment(invoice.date).format('DD MMM YYYY'),
              contract: { compensation: invoice.compensation },
            }
          })) ||
      [],
    [data]
  )
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle> Invoices </PageTitle>
      </div>
      <CustomTable
        notFoundTitle="No payment history"
        page={page}
        loading={isLoading}
        fetching={isFetching}
        isSuccess={isSuccess}
        pageSize={limit}
        total={data?.data?.count || 0}
        fields={fields}
        data={getInvoicesTable}
        onPage={setPage}
        sorting={sorting}
        setSorting={setSorting}
      />
    </div>
  )
}
