import CustomTable from '@atoms/Table/CustomTable'
import { TIMESHEET_STATE } from '@core/constants'
import usePagination from '@core/hooks/usePagination'
import { getAllTimesheets } from '@services/timesheets.service'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { getTimesheetFields } from '../mock'

export const PendingTimesheets = () => {
  const navigate = useNavigate()

  const { page, limit, setPage } = usePagination({ page: 1, limit: 5 })

  const { data, isFetching, isLoading } = useQuery([getAllTimesheets.key, page, limit], {
    queryFn: () =>
      getAllTimesheets.fetch({
        limit,
        offset: (page - 1) * limit,
        state: TIMESHEET_STATE.PENDING,
      }),
  })

  const handleView = (id) => {
    navigate(`${id}`)
  }

  return (
    <CustomTable
      fields={getTimesheetFields(handleView)}
      data={data?.results || []}
      onPage={setPage}
      loading={isFetching || isLoading}
      page={page}
      total={data?.count || 0}
      pageSize={limit}
    />
  )
}
