import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import Input from '@atoms/Input/Input'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import { Tooltip } from '@atoms/Tooltip/tooltip.component'
import Typography from '@atoms/Typography/Typography'
import { EMPLOYMENT_TYPE } from '@core/constants'
import { StringToBoolean } from '@core/utils'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { v4 } from 'uuid'

import { parseDetailsToConfig } from './probation-period.utils'
import ProbationPeriodInfo from './ProbationPeriodInfo'

const getCustomStartDate = () => {
  const customStartDate = new Date('2024-01-04')
  const startDate = new Date()
  startDate.setDate(startDate.getDate() + 8)
  if (moment(startDate).isBefore(customStartDate)) return customStartDate
  return startDate
}

const isWorkingHoursSpecificCase = (employmentType, workScheduleConfig) => {
  if (!employmentType || !workScheduleConfig) return false
  return employmentType === EMPLOYMENT_TYPE.FullTime && workScheduleConfig.full_time_specific_case
}

const getWorkingHoursPerWeekRange = (employmentType, workScheduleConfig) => {
  const result = {
    min: 0,
    max: 0,
    suggested: 0,
  }
  if (!employmentType || !workScheduleConfig) return result
  const minWeekFullTime =
    workScheduleConfig.full_time_min_work_days_per_week * workScheduleConfig.full_time_min_work_hours_per_day
  const maxWeekFullTime =
    workScheduleConfig.full_time_max_work_days_per_week * workScheduleConfig.full_time_max_work_hours_per_day
  const suggestedFullTime = workScheduleConfig.full_time_suggested_work_hours_per_week

  const minWeekPartTime = workScheduleConfig.part_time_min_work_hours_per_week
  const maxWeekPartTime = workScheduleConfig.part_time_max_work_hours_per_week
  const suggestedPartTime = workScheduleConfig.part_time_suggested_work_hours_per_week

  if (employmentType === EMPLOYMENT_TYPE.FullTime) {
    result.min = minWeekFullTime
    result.max = maxWeekFullTime
    result.suggested = suggestedFullTime
  }

  if (employmentType === EMPLOYMENT_TYPE.PartTime) {
    result.min = minWeekPartTime
    result.max = maxWeekPartTime
    result.suggested = suggestedPartTime
  }

  return result
}

export default ({
  onNext,
  draft,
  hasOccupations,
  occupations,
  probationPeriodDetails,
  livingCountryName,
  workScheduleDetails,
}) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      position: '',
      position_description: '',
      starts_at: '',
      ends_at: '',
      department: '',
      is_permanent: 'true',
      employment_type: EMPLOYMENT_TYPE.FullTime,
      employee_work_hours_per_week: 0,
    },
    mode: 'onChange',
  })

  const employment_type_watch = watch('employment_type')
  const starts_at_watch = watch('starts_at')
  const ends_at_watch = watch('ends_at')
  const probation_period = watch('probation_period')
  const is_permanent_watch = StringToBoolean(watch('is_permanent'))
  const [probationPeriodConfig, setProbationPeriodConfig] = useState(null)
  const [workScheduleConfig, setWorkScheduleConfig] = useState(null)
  const workingHoursRange = getWorkingHoursPerWeekRange(employment_type_watch, workScheduleConfig)
  const employmentTypeText = employment_type_watch === EMPLOYMENT_TYPE.FullTime ? 'full-time' : 'part-time'

  useEffect(() => {
    if (probationPeriodConfig?.suggested) {
      setValue('probation_period', probationPeriodConfig.suggested)
    }
  }, [probationPeriodConfig, probation_period, setValue])

  useEffect(() => {
    setValue('employee_work_hours_per_week', workingHoursRange.suggested)
  }, [employment_type_watch, workScheduleConfig])

  const startDate = new Date()
  startDate.setDate(startDate.getDate() + 8)

  useEffect(() => {
    if (draft?.job) {
      reset({
        position: draft.job.position,
        position_description: draft.job.position_description,
        starts_at: new Date(draft.job.starts_at),
        ends_at: new Date(draft.job.ends_at),
        department: draft.department,
        is_permanent: draft.job.is_permanent.toString(),
        occupation: draft.job?.occupation,
        probation_period: draft.job?.probation_period,
      })
    }
  }, [draft, reset])

  useEffect(() => {
    if (!draft?.job?.ends_at) {
      setValue('ends_at', '')
    }
  }, [starts_at_watch, setValue, draft?.job?.ends_at])

  useEffect(() => {
    const newConfig = parseDetailsToConfig(probationPeriodDetails, starts_at_watch, ends_at_watch, is_permanent_watch)
    setProbationPeriodConfig(newConfig)
  }, [is_permanent_watch, starts_at_watch, ends_at_watch, probationPeriodDetails])

  useEffect(() => {
    setWorkScheduleConfig(workScheduleDetails)
  }, [workScheduleDetails])

  const submit = ({ ends_at, starts_at, department, ...formValues }) => {
    const { employee_work_hours_per_week, ...otherFormValues } = formValues
    if (otherFormValues.occupation !== undefined) {
      otherFormValues.occupation = otherFormValues.occupation.value
    }
    onNext({
      job: {
        ...otherFormValues,
        ends_at: moment(ends_at).format('YYYY-MM-DD'),
        requested_starts_at: moment(starts_at).format('YYYY-MM-DD'),
        is_permanent: otherFormValues.is_permanent === 'true',
      },
      compensation: {
        employee_work_hours_per_week,
      },
      department,
    })
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="d-flex flex-column h-100 employees-page__form">
      <section className=" flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 remo-form-input">
          <Input
            data-testid="JobDetailsForm-C5F9D9"
            {...register('position', {
              required: 'Job title is required',
              maxLength: {
                value: 128,
                message: 'Job title cannot exceed 128 characters',
              },
            })}
            type="text"
            label="Job title"
            placeholder="e.g. Developer"
            isRequired
          />
          {errors.position && <Typography className="text_regular__14 color_red">{errors.position.message}</Typography>}
        </div>

        <div className="w-100 remo-form-input">
          <TextArea
            {...register('position_description', {
              required: 'Job description is required',
              maxLength: {
                value: 2000,
                message: 'Job description cannot exceed 2000 characters',
              },
            })}
            label="Job description"
            placeholder="Describe general tasks or other related duties and responsibilities of the position"
            isRequired
          />
          {errors.position_description && (
            <Typography className="text_regular__14 color_red">{errors.position_description.message}</Typography>
          )}
        </div>

        {hasOccupations && occupations && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="occupation"
              rules={{
                required: 'Occupation type is required',
                maxLength: {
                  value: 128,
                  message: 'Occupation cannot exceed 128 characters',
                },
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  data-testid="JobDetailsForm-9132B1"
                  label="Occupation type"
                  placeholder="Select"
                  options={occupations?.map((o) => ({ value: o.id, label: o.name })) || []}
                  isRequired
                />
              )}
            />
            {errors.occupation && (
              <Typography className="text_regular__14 color_red">{errors.occupation.message}</Typography>
            )}
          </div>
        )}

        <div className="w-100 remo-form-input">
          <Input
            data-testid="JobDetailsForm-911CB1"
            {...register('department', {
              maxLength: {
                value: 128,
                message: 'Department cannot exceed 128 characters',
              },
            })}
            type="text"
            label="Department"
            placeholder="Department"
          />
          {errors.department && (
            <Typography className="text_regular__14 color_red">{errors.department.message}</Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="employee_work_hours_per_week"
            rules={{
              required: {
                value: true,
                message: 'Working hours per week is required',
              },
              min: {
                value: workingHoursRange.min,
                message: `Per local labor laws, the minimum is ${workingHoursRange.min} hours per week`,
              },
              max:
                employment_type_watch === EMPLOYMENT_TYPE.PartTime ||
                isWorkingHoursSpecificCase(employment_type_watch, workScheduleConfig)
                  ? {
                      value: workingHoursRange.max,
                      message: `Per local labor laws, the maximum is ${workingHoursRange.max} hours per week`,
                    }
                  : {},
            }}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                label="Working hours per week"
                disabled={
                  employment_type_watch !== EMPLOYMENT_TYPE.PartTime &&
                  !isWorkingHoursSpecificCase(employment_type_watch, workScheduleConfig)
                }
                addText={`
                  Standard ${employmentTypeText} working week in ${livingCountryName} is ${workingHoursRange.suggested} hours.
                  The minimum hours per week is ${workingHoursRange.min} hours${employment_type_watch === EMPLOYMENT_TYPE.PartTime || isWorkingHoursSpecificCase(employment_type_watch, workScheduleConfig) ? `, and the maximum is ${workingHoursRange.max}` : ''}.
                `}
              />
            )}
          />
          {errors.employee_work_hours_per_week && (
            <Typography className="text_regular__14 color_red">
              {errors.employee_work_hours_per_week.message}
            </Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="is_permanent"
            rules={{ required: 'Contract type is required' }}
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Contract type"
                layout="vertical"
                options={[
                  {
                    id: 'is_permanent_false',
                    value: true,
                    text: 'Permanent (without specific date of contract termination)',
                  },
                  {
                    id: 'is_permanent_true',
                    value: false,
                    text: 'Fixed term (for contractor with a specified period)',
                  },
                ]}
                isRequired
              />
            )}
          />
          {errors.is_permanent && (
            <Typography className="text_regular__14 color_red">{errors.is_permanent.message}</Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="starts_at"
            rules={{ required: 'Start date is required' }}
            render={({ field }) => {
              return (
                <DateInput
                  isRequired
                  {...field}
                  minDate={getCustomStartDate()}
                  label="Start date"
                  addText="You can choose a start date no earlier than 7 working days from today. If employee needs to start earlier, we can pay a sign-in bonus to make up the difference. But the official start date will be not earlier than seven days from today"
                />
              )
            }}
          />
          {errors.starts_at && (
            <Typography className="text_regular__14 color_red">{errors.starts_at.message}</Typography>
          )}
        </div>

        {!is_permanent_watch && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="ends_at"
              rules={{ required: 'End date is required' }}
              render={({ field }) => {
                return <DateInput {...field} label="End date" minDate={starts_at_watch} isRequired />
              }}
            />
            {errors.ends_at && <Typography className="text_regular__14 color_red">{errors.ends_at.message}</Typography>}
          </div>
        )}

        {probationPeriodConfig && !probationPeriodConfig.noProbation && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="probation_period"
              rules={{
                required: {
                  value: !!probationPeriodConfig?.applicable,
                  message: 'Probation period is required',
                },
                min: {
                  value: probationPeriodConfig?.min || 0,
                  message: 'Probation period is not in range',
                },
                max: {
                  value: probationPeriodConfig?.max || 0,
                  message: 'Probation period is not in range',
                },
              }}
              render={({ field }) => {
                return probationPeriodConfig?.applicable ? (
                  <Input
                    {...field}
                    type="number"
                    label="Probation period"
                    tooltip={
                      <Tooltip
                        id={v4()}
                        clickable
                        content={
                          <ProbationPeriodInfo
                            probationDetails={probationPeriodDetails}
                            probationPeriodConfig={probationPeriodConfig}
                            isPermanent={is_permanent_watch}
                            title={`Probation period duration guide for ${
                              is_permanent_watch ? 'permanent' : 'fixed'
                            } contract in ${livingCountryName}:`}
                          />
                        }
                      />
                    }
                    addText={
                      probationPeriodConfig?.applicable
                        ? `Per local labor laws, the minimum is ${probationPeriodConfig.min} days and the maximum is ${probationPeriodConfig.max} days. ${probationPeriodConfig?.note || ''}${!!probationPeriodConfig?.suggested && probation_period !== probationPeriodConfig?.suggested ? `\nRemofirst recommends a minimum of ${probationPeriodConfig.suggested} days probation period for all EOR employees to guarantee a smoother experience in case of early termination.` : ''}`
                        : null
                    }
                    isRequired
                  />
                ) : (
                  <>
                    <Typography className="text_medium__14 d-flex align-items-center gap-1 remo-form-label">
                      Probation period
                    </Typography>
                    <NotificationBlock
                      render={
                        <Typography className="text_light__12">
                          {`The probationary period does not apply. ${probationPeriodConfig?.note || ''}`}
                        </Typography>
                      }
                    />
                  </>
                )
              }}
            />
            {errors.probation_period && (
              <Typography className="text_regular__14 color_red">{errors.probation_period.message}</Typography>
            )}
          </div>
        )}
      </section>

      <div className="align-self-center pb-5">
        <Button data-testid="JobDetailsForm-7305CD" type="submit" className="align-self-end">
          Continue
        </Button>
      </div>
    </form>
  )
}
