import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { playIcon, questionFilledIcon } from '@core/icons/icons'
import { useState } from 'react'
import { Image, Modal, Stack } from 'react-bootstrap'
import styled from 'styled-components'

import paymentInfo from '../../assets/images/paymentInfo.svg'

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 0;
    border-radius: 0 !important;
  }
  .custom-modal-width {
    max-width: 918px;
    width: 100%;
  }
`
export const InfoModal = ({ show, handleClose }) => {
  return (
    <StyledModal show={show} onHide={handleClose} dialogClassName="custom-modal-width" centered>
      <Modal.Header closeButton style={{ marginBottom: '0px' }}>
        <Typography className="heading_semibold__24">Payment made easy</Typography>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#F9F7F0' }}>
        <Stack gap={4}>
          <Image src={paymentInfo} fluid />

          <Typography className="dm-sans_heading_semibold__24">
            <a
              href="https://app.supademo.com/demo/cm3qufz200dcq3vuktlf4c383?preview=true"
              target="_blank"
              style={{ color: '#40B84C', fontWeight: 500 }}
              rel="noreferrer"
            >
              <Stack direction="horizontal" style={{ gap: '12px', justifyContent: 'center' }}>
                <Icon icon={playIcon} />
                See interactive demo for detailed process
              </Stack>
            </a>
          </Typography>
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" size="small" priority="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}

export const PayContractorInfoButton = () => {
  const [showInfo, setShowInfo] = useState(false)
  const handleInfoClose = () => setShowInfo(false)
  const handleInfoShow = () => setShowInfo(true)
  return (
    <>
      <Button
        priority="outlined"
        type="button"
        size="small"
        style={{
          padding: '10px 16px',
          fontSize: '16px',
          lineHeight: '20px',
          alignSelf: 'flex-start',
        }}
        onClick={handleInfoShow}
      >
        <Stack direction="horizontal" style={{ alignItems: 'center' }}>
          <Icon icon={questionFilledIcon} className="mr-2" />
          <span>How it works</span>
        </Stack>
      </Button>
      <InfoModal show={showInfo} handleClose={handleInfoClose} />
    </>
  )
}
