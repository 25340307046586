import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

export default ({ livingCountry, onNext, draft, regionName }) => {
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      living_country: livingCountry,
      street_address: '',
      address_line: '',
      state: regionName || '',
      city: '',
      postal_code: '',
    },
    mode: 'onChange',
  })

  const { countries } = useApp()

  useEffect(() => {
    if (livingCountry) {
      setValue('living_country', livingCountry)
    }
  }, [livingCountry, setValue])

  useEffect(() => {
    if (regionName) {
      setValue('state', regionName)
    }
  }, [regionName, setValue])

  useEffect(() => {
    if (draft?.profile?.address) {
      reset({
        ...draft.profile.address,
      })
    }
  }, [draft, reset])

  const submit = (formValues) => {
    onNext({
      ...formValues,
      living_country: formValues.living_country.value || formValues.living_country,
    })
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="d-flex flex-column h-100 employees-page__form">
      <section className=" flex-grow-1 d-flex flex-column  justify-content-center align-items-center">
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="living_country"
            rules={{ required: 'Country is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="LocationForm-F09FFE"
                  {...field}
                  label="Country"
                  placeholder="Select country"
                  options={countries.map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))}
                />
              )
            }}
          />
          {errors.living_country && (
            <Typography className="text_regular__14 color_red">{errors.living_country.message}</Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="LocationForm-408002"
            {...register('street_address', {
              required: 'Address line 1 is required',
              maxLength: {
                value: 256,
                message: 'Address line cannot exceed 256 characters',
              },
            })}
            type="text"
            label="Address line 1"
            placeholder="Enter"
            isRequired
          />
          {errors.street_address && (
            <Typography className="text_regular__14 color_red">{errors.street_address.message}</Typography>
          )}
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="LocationForm-13A9FE"
            {...register('address_line', {
              maxLength: {
                value: 256,
                message: 'Address line cannot exceed 256 characters',
              },
            })}
            type="text"
            label="Address line 2"
            placeholder="Enter"
          />
          {errors.address_line && (
            <Typography className="text_regular__14 color_red">{errors.address_line.message}</Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="LocationForm-309A40"
            {...register('city', {
              required: 'City is required',
              maxLength: {
                value: 256,
                message: 'City cannot exceed 256 characters',
              },
            })}
            type="text"
            label="City"
            placeholder="Enter"
            isRequired
          />
          {errors.city && <Typography className="text_regular__14 color_red">{errors.city.message}</Typography>}
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="LocationForm-206D90"
            {...register('state', {
              maxLength: {
                value: 256,
                message: 'State cannot exceed 256 characters',
              },
            })}
            type="text"
            label="Region / State / Province"
            placeholder="Enter"
          />
          {errors.state && <Typography className="text_regular__14 color_red">{errors.state.message}</Typography>}
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="LocationForm-5E2269"
            {...register('postal_code', {
              required: 'Postcode / Zip code is required',
              maxLength: {
                value: 256,
                message: 'Postcode / Zip code cannot exceed 256 characters',
              },
            })}
            type="text"
            label="Postcode / Zip code"
            placeholder="Enter"
            isRequired
          />
          {errors.postal_code && (
            <Typography className="text_regular__14 color_red">{errors.postal_code.message}</Typography>
          )}
        </div>
      </section>

      <div className="align-self-center pb-5">
        <Button data-testid="LocationForm-6B8F6F" type="submit" className="align-self-end">
          Continue
        </Button>
      </div>
    </form>
  )
}
