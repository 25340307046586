import './NotificationBlock.scss'

import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { clear, notificationIcon, notificationIconRed } from '@core/icons/icons'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { MdChevronRight } from 'react-icons/md'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

const StyledIcon = styled(MdChevronRight)`
  transform: ${(p) => (p.open ? 'rotate(-90deg)' : 'rotate(90deg)')};
`

export default function NotificationBlock({ isExpanded, onClose, text, render, isError, cards, styledClass, icon }) {
  const { value, toggle, setValue } = useBoolean(isExpanded || false)

  useEffect(() => {
    setValue(isExpanded)
  }, [isExpanded, setValue])

  return (
    <div className={classNames(['notification-block', styledClass, isError && 'notification-block_error'])}>
      <div className="d-flex justify-content-between" role="button" aria-hidden onClick={toggle}>
        <div className="d-flex align-items-center w-100">
          <div>
            <Icon icon={icon || (isError ? notificationIconRed : notificationIcon)} style={{ cursor: 'pointer' }} />
          </div>
          {text && <Typography className="text_regular__14 ml-2">{text}</Typography>}
          {render && render}
        </div>

        {!onClose && cards && <StyledIcon open={value} size={28} />}

        {onClose && <Icon onClick={onClose} icon={clear} fill="#000000" style={{ cursor: 'pointer' }} />}
      </div>
      {value && cards && (
        <div className="d-flex justify-content-between mt-3 gap-2">
          {cards.map((card) => (
            <div key={v4()} className="notification-card">
              <div style={{ width: 15 }}>
                <Icon icon={card.icon} stroke="#137547" fill="none" />
              </div>
              <div className="d-flex flex-column ml-2">
                <Typography className="text_medium__14">{card.title}</Typography>
                <Typography className="text_regular__14 color_text_300">{card.description}</Typography>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
