export default function NotGenerated() {
  return (
    <svg width="129" height="146" viewBox="0 0 129 146" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M84.7676 34.6818L92.5559 20" stroke="#40b84c" strokeWidth="1.44289" strokeLinecap="round" />
      <path d="M97.748 43.1742L108.854 34.25" stroke="#40b84c" strokeWidth="1.44289" strokeLinecap="round" />
      <path d="M69.9132 33.2424L62.9902 1" stroke="#40b84c" strokeWidth="1.44289" strokeLinecap="round" />
      <path d="M58.3747 34.5379L49.8652 22.0151" stroke="#40b84c" strokeWidth="1.44289" strokeLinecap="round" />
      <path d="M0.539062 110.969L18.4233 97.583L20.7309 111.689L71.7591 144.856L0.539062 110.969Z" fill="#40b84c" />
      <path
        d="M50.875 92.5945L67.0285 83.8257L93.1337 99.8812"
        stroke="#121212"
        strokeWidth="1.44289"
        strokeLinecap="round"
      />
      <path
        d="M50.8755 92.5944L75.8651 109.05C76.044 109.168 76.2735 109.177 76.4612 109.074L93.1343 99.8812L126.592 81.566C126.98 81.3537 126.995 80.8022 126.619 80.5692L65.2857 42.5421C65.1144 42.4359 64.9001 42.4268 64.7204 42.518L14.8397 67.8303C14.4446 68.0308 14.4134 68.5833 14.7835 68.827L50.8755 92.5944Z"
        stroke="#121212"
        strokeWidth="1.44289"
        strokeLinecap="round"
      />
      <path d="M65.0098 42.3711L67.029 83.8256" stroke="#121212" strokeWidth="1.44289" strokeLinecap="round" />
      <path
        d="M76.1541 109.242L72.0354 143.881C71.9798 144.348 72.4765 144.682 72.8881 144.454L114.443 121.444C114.572 121.373 114.669 121.255 114.714 121.114L127.458 81.0908"
        stroke="#121212"
        strokeWidth="1.44289"
        strokeLinecap="round"
      />
      <path
        d="M72.4795 144.568L21.0187 111.318L14.5 68.4243"
        stroke="#121212"
        strokeWidth="1.44289"
        strokeLinecap="round"
      />
    </svg>
  )
}
