import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { Form } from './auth-modal.styles'

export const RecoveryCodeForm = ({ isLoading, onSubmit, onClickLostRecoveryCode }) => {
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({
    defaultValues: { recovery_code: '' },
    mode: 'onChange',
  })
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        data-testid="recovery-code-form.component-5C45AA"
        {...register('recovery_code', {
          required: 'Required',
        })}
      />

      <Button
        data-testid="recovery-code-form.component-AAA8EF"
        loading={isLoading}
        disabled={!isValid}
        type="submit"
        size="medium"
        style={{ marginTop: '32px' }}
      >
        Continue
      </Button>

      <Typography
        role="button"
        aria-hidden
        style={{
          color: '#40b84c',
          alignSelf: 'center',
        }}
        onClick={() => onClickLostRecoveryCode()}
      >
        Lost your recovery code?
      </Typography>
    </Form>
  )
}

export const StyledInput = styled.input`
  border-radius: 8px;
  width: 3rem !important;
  height: 4rem;
  font-size: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.3);

  :focus {
    outline: none;
    border-color: #40b84c;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`
