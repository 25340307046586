import RadioButton from '@atoms/RadioButton/RadioButton'
import Typography from '@atoms/Typography/Typography'
import { Controller, useFormState, useWatch } from 'react-hook-form'

export const RemoCheckFormSection = ({ control, isTargetContractor }) => {
  const { errors } = useFormState({ control })
  const watchUseRemoCheck = useWatch({
    control,
    name: 'use_remo_check',
  })
  const checkTargetLabel = isTargetContractor ? 'contractor' : 'employee'
  return (
    <>
      <div className="w-100 remo-form-input">
        <Controller
          control={control}
          name="use_remo_check"
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <RadioButton
              {...field}
              isRequired
              label={`Would you like to use our RemoCheck service for the ${checkTargetLabel}’s background check?`}
              layout="vertical"
              options={[
                {
                  id: 'use_remo_check_true',
                  value: true,
                  text: `Yes, I need to background-check the ${checkTargetLabel}`,
                },
                {
                  id: 'use_remo_check_false',
                  value: false,
                  text: `No, I don’t need to background-check the ${checkTargetLabel}`,
                },
              ]}
              addText={
                <span>
                  Strongly recommended,{' '}
                  <a
                    // href="https://remofirst-static.s3.amazonaws.com/remohealth/RemoCheck%20-%20Product%20Booklet%202024.pdf"
                    href="https://remofirst.atlassian.net/wiki/external/NGQ4ZWY2ODA0NzY1NGRjNWI4MDVkZmRkMDA2NGJlMDc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    see service details
                  </a>
                </span>
              }
            />
          )}
        />
        {errors.use_remo_check && (
          <Typography className="text_regular__14 color_red">{errors.use_remo_check.message}</Typography>
        )}
      </div>
      {watchUseRemoCheck === 'true' && (
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="service_plan"
            rules={{ required: 'Plan is required' }}
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Please choose the RemoCheck service plan wanted"
                layout="vertical"
                isRequired
                options={[
                  {
                    id: 'service_plan_basic',
                    value: 'basic',
                    text: 'Basic',
                  },
                  {
                    id: 'service_plan_standard',
                    value: 'standard',
                    text: 'Standard',
                  },
                  {
                    id: 'service_plan_management',
                    value: 'management',
                    text: 'Management',
                  },
                ]}
              />
            )}
          />
          {errors.service_plan && (
            <Typography className="text_regular__14 color_red">{errors.service_plan.message}</Typography>
          )}
        </div>
      )}
    </>
  )
}
