import { Modal as BsModal } from 'react-bootstrap'
import styled from 'styled-components'

const Modal = styled(BsModal)`
  .modal-content {
    padding: 0 !important;
    border-radius: 0 !important;
  }
`

const Card = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(38, 40, 66, 0.08);
  background: #fff;
  padding: 16px;
  width: 464px;
`

const Table = styled.table`
  width: unset !important;
  thead {
    background-color: #f5f5f5;
  }

  th,
  td {
    padding: 10px 10px;
  }
`

const Link = styled.a`
  text-decoration: none;
`

const UploadButton = styled.span`
  color: #40b84c;
`

export const Styled = { Modal, Card, Table, Link, UploadButton }
