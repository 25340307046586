import styled from 'styled-components'

const Root = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`
const Value = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #137547;
`

const Detail = styled.div`
  font-size: 10px;
  line-height: 14px;
`

export const Styled = {
  Root,
  Label,
  Value,
  Detail,
}
