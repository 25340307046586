import { Api } from '@api/Api'

export const getInvoicesByContractId = async (id, page, limit, sorting) => {
  const sortingParam = sorting ? `&ordering=${sorting.desc ? '-' : ''}${sorting.id}` : ''
  return Api.get(`invoices/?contract_id=${id}&offset=${(page - 1) * limit}&limit=${limit}${sortingParam}`)
}

export const addInvoicesByContractId = async (data) => Api.post(`invoices/`, data)

export const deleteInvoicesById = async (id) => Api.delete(`invoices/${id}/delete/`)
