import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import List from '@atoms/List/List'
import Typography from '@atoms/Typography/Typography'
import { InfoMessage } from '@components/info-message/info-message.component'
import { useToast } from '@core/hooks/useNotification'
import { Styled } from '@pages/employees/create-employee/forms/time-off-policy-step/time-off-policy-step.styles'
import ListHolidaysModal from '@pages/time-off/ListHolidaysModal'
import { getContractsPaidLeaves, postContractsPaidLeaves } from '@services/contract.service'
import { getHolidays } from '@services/holidays.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

const Container = styled.div`
  border: 2px solid #f3f3f3;
  border-radius: 8px;
  height: 100%;
  padding: 16px;
  padding-bottom: 0;
`

export default function TimeOffEmployee({ employee }) {
  const { successAlert } = useToast()
  const [visibleEdit, setVisibleEdit] = useState(false)
  const publicHolidaysModalState = useBoolean(false)

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    getFieldState,
    watch,
    formState: { errors },
    reset,
    register,
  } = useForm({
    defaultValues: {
      paid_leave: {
        annual_days_by_contract: 0,
      },
      paid_sick_leave: {
        annual_days_by_contract: 0,
      },
    },
  })

  const holidays = useQuery(['holidays', employee.working_country.id, employee.region?.id], {
    queryFn: () => {
      const params = {
        limit: 1000,
        country_id: employee.working_country.id,
        year: new Date().getFullYear(),
      }
      if (employee.region?.id) params.regions = employee.region.id
      return getHolidays(params)
    },
    enabled: !!employee.working_country.id,
  })

  const { data, refetch, isLoading } = useQuery(['getContractsPaidLeaves', employee], {
    queryFn: () => getContractsPaidLeaves(employee.id),
    onSuccess: (res) => {
      reset({ ...res.leave_days })
    },
  })

  const updateContractsPaidLeaves = useMutation({
    mutationFn: () => postContractsPaidLeaves(employee.id, getValues()),
    onSuccess: () => {
      refetch()
      successAlert('Successfully updated!')
      setVisibleEdit(false)
    },
  })

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <DotWave />
      </div>
    )
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Typography className="heading_semibold__20 color_black">Time-off</Typography>
      </div>
      {data && (
        <div className="row justify-content-between" style={{ gap: 16, width: '500px' }}>
          <div>
            <Controller
              control={control}
              name="currency"
              rules={{ required: 'Paid time off is required' }}
              render={({ field }) => {
                return (
                  <Input
                    data-testid="PaidTimeOffModal-D269580"
                    {...register('paid_leave.annual_days_by_contract', {
                      required: 'Field is required',
                      pattern: {
                        value: /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
                        message: 'Please enter valid number',
                      },
                    })}
                    type="text"
                    label="Annual paid time off days"
                    placeholder="Enter number of days"
                    value={field.value}
                    disabled={!visibleEdit}
                  />
                )
              }}
            />
            {errors.currency && (
              <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>
            )}
          </div>
          <div className="w-100">
            <Controller
              control={control}
              name="currency"
              rules={{ required: 'Sick leave is required' }}
              render={({ field }) => {
                return (
                  <Input
                    data-testid="PaidTimeOffModal-D269580"
                    {...register('paid_sick_leave.annual_days_by_contract', {
                      required: 'Field is required',
                      pattern: {
                        value: /^(([0-9]*)|(([0-9]*)\.([0-9]*)))$/,
                        message: 'Please enter valid number',
                      },
                    })}
                    type="text"
                    label="Sick leave days"
                    placeholder="Enter number of days"
                    value={field.value}
                    disabled={!visibleEdit}
                  />
                )
              }}
            />
            {errors.currency && (
              <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>
            )}
          </div>
          <div className="w-100">
            {holidays.data?.count && (
              <InfoMessage
                type="info"
                message={
                  <span>
                    In addition to the paid time off and sick leave days specified above, the employee is also entitled
                    to
                    {` ${holidays.data?.count}`} days of regional/national public holidays depending on their region,
                    excluding weekends.{' '}
                    <Styled.LinkButton type="button" onClick={publicHolidaysModalState.setTrue}>
                      See breakdown
                    </Styled.LinkButton>
                  </span>
                }
              />
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between gap-2">
            {!visibleEdit ? (
              <Button
                data-testid="Personal-7A0795"
                priority="secondary"
                size="small"
                onClick={() => setVisibleEdit(true)}
              >
                Edit
              </Button>
            ) : (
              <Button
                data-testid="Personal-7A0795"
                priority="primary"
                size="small"
                onClick={updateContractsPaidLeaves.mutate}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      )}
      {publicHolidaysModalState.value && <ListHolidaysModal onClose={publicHolidaysModalState.setFalse} />}
    </div>
  )
}
