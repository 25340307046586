import Button from '@atoms/Button/Button'
import { TIMESHEET_STATE } from '@core/constants'
import { formatMinutesToHours } from '@core/utils'
import moment from 'moment'

import { TimesheetStatus } from './timesheetStatus/TimesheetStatus'

export const getTimesheetFields = (handleView) => [
  {
    title: 'Employee',
    key: 'full_name',
    type: 'avatar',
    maxWidth: 400,
    add_key: 'job_position',
    minWidth: 300,
  },
  {
    title: 'Period',
    type: 'custom',
    maxWidth: 300,
    minWidth: 200,
    render: (item) => {
      return `${moment(item.start_period).format('D MMM YYYY')} - ${moment(item.end_period).format('D MMM YYYY')}`
    },
  },
  {
    title: 'Total no. of hours',
    key: 'total_minutes_spent',
    type: 'custom',
    maxWidth: 300,
    minWidth: 200,
    render: (item) => formatMinutesToHours(item.total_minutes_spent),
  },
  {
    title: 'Status',
    key: 'status',
    type: 'custom',
    maxWidth: 300,
    minWidth: 200,
    render: (item) => <TimesheetStatus status={item.state} />,
  },
  {
    title: 'Action',
    type: 'custom',
    minWidth: 150,
    render: ({ state, id }) => {
      return (
        <div>
          {(state === TIMESHEET_STATE.REJECTED ||
            state === TIMESHEET_STATE.APPROVED ||
            state === TIMESHEET_STATE.DRAFT ||
            state === TIMESHEET_STATE.PAID ||
            state === TIMESHEET_STATE.PENDING_INVOICE ||
            state === TIMESHEET_STATE.PENDING_PAYMENT_APPROVAL) && (
            <Button onClick={() => handleView(id)} size="xsmall" priority="secondary">
              View
            </Button>
          )}
          {state === TIMESHEET_STATE.PENDING && (
            <Button onClick={() => handleView(id)} size="xsmall">
              Review
            </Button>
          )}
        </div>
      )
    },
  },
]
