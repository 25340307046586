import React, { useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  height: calc(100% - 40px); // 40px is margin-top of header
  width: 100%;
`
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  max-width: 720px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid #dfdfdf;
`
const Body = styled.div`
  flex: 1;
  overflow-y: scroll;
`
const Footer = styled.div`
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.h2`
  font-family: 'Manrope';
  font-weight: 600;
  font-size: 1.5rem;
`

const ContactText = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: #121212;

  a {
    color: #137547;
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  button {
    width: 120px;
  }
`

export const DocumentPreview = ({ title, file, headerActions, footerActions }) => {
  const ref = useRef(null)
  const [scrolled, setScrolled] = useState(false)

  useLayoutEffect(() => {
    if (ref && ref.current.scrollHeight === ref.current.clientHeight) {
      setScrolled(true)
    }
  }, [])

  const handleScroll = () => {
    if (ref.current) {
      const spare = 200
      const { scrollTop, scrollHeight, clientHeight } = ref.current
      if (scrollTop + clientHeight >= scrollHeight - spare) {
        setScrolled(true)
      }
    }
  }

  return (
    <Wrapper>
      <Container>
        <Content>
          <Header>
            <Title>{title}</Title>
            <Actions>{headerActions?.map((action) => action)}</Actions>
          </Header>
          <Body onScroll={handleScroll} ref={ref}>
            <embed src={`${file}#toolbar=0`} type="application/pdf" height="100%" width="100%" />
          </Body>
          <Footer>
            <ContactText>
              Any questions? Contact us at{' '}
              <span>
                <a data-testid="document-preview.component-4CFAF7" href="mailto:help@remofirst.com">
                  help@remofirst.com
                </a>
              </span>
            </ContactText>
            <Actions>{footerActions?.map((action) => action)}</Actions>
          </Footer>
        </Content>
      </Container>
    </Wrapper>
  )
}
