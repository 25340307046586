import React from 'react'
import styled from 'styled-components'

const Root = styled.div``
const Step = styled.div`
  padding: 0.625rem 1rem;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
  display: flex;
  margin-bottom: 0.5rem;
  max-width: 410px;
`
const Left = styled.div``
const Number = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: #137547;
  width: 16px;
  height: 16px;
  border: 2px solid #137547;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  margin-right: 8px;
`
const Right = styled.div``
const Title = styled.div`
  color: #121212;
  font-size: 0.875rem;
  line-height: 150%;
  font-weight: 500;
`
const Description = styled.div`
  color: #878787;
  font-size: 0.875rem;
  line-height: 150%;
`

export const StepSequence = ({ steps }) => {
  return (
    <Root>
      {steps.map(({ title, description }, index) => (
        <Step key={title}>
          <Left>
            <Number>{index + 1}</Number>
          </Left>
          <Right>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Right>
        </Step>
      ))}
    </Root>
  )
}
